import * as React from "react";
import {
  Button,
  FormControl,
  Input,
  Select,
  useToast,
  View,
  Box,
  Text,
} from "native-base";
import styles from "./styles";
import {
  Buildings,
  Company,
  Geo,
  Map,
  Parcel,
  World,
} from "../../../assets/images/svg";
import { americanStates } from "../../../assets/data/americanStates";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import {
  clearErrorMessage,
  clearUserInfo,
  setUser,
} from "../../../networking/auth/authSlice";
import { registerUser } from "../../../networking/auth/authApi";
import { GOOGLE_API_KEY } from "../../../config";
import { useNavigate, useParams } from "react-router-dom";
import Autocomplete from "react-google-autocomplete";
import {
  toastErrorConfig,
  toastSuccessConfig,
} from "../../../config/constants";
import { passkeyRegister } from "../../../networking/auth/authApi";

export default function URFSecondScreen() {
  const dispatch = useDispatch();
  const toast = useToast();
  const ref = useRef();
  const navigate = useNavigate();

  const { userInfo, loading, success, message } = useSelector(
    (state) => state.auth
  );
  const [streetAddressLine1, setStreetAddressLine1] = useState(
    userInfo.streetAddressLine1 || ""
  );
  const [streetAddressLine2, setStreetAddressLine2] = useState(
    userInfo.streetAddressLine2 || ""
  );
  const [city, setCity] = useState(userInfo.city || "");
  const [state, setState] = useState(userInfo.state || "");
  const [zip, setZip] = useState(userInfo.zip || "");
  const [county, setCounty] = useState(userInfo.county || "");
  const [addressLine, setAddressLine] = useState(streetAddressLine1);

  const onPasskeyRegister = async () => {
    try {
      await dispatch(passkeyRegister()).unwrap();
      toast.show({
        description: "Passkey registration successful",
        ...toastSuccessConfig,
      });
    } catch (error) {
      {
        toast.show({
          description: error || "Passkey registration failed",
          ...toastErrorConfig,
        });
      }
    }
  };

  useEffect(() => {
    return () => {
      dispatch(clearErrorMessage());
    };
  }, []);

  const goToNextPage = async () => {
    try {
      // Dispatch the registerUser action and wait for its completion
      await dispatch(
        registerUser({
          data: {
            ...userInfo,
            isEmailVerified: userInfo.isEmailVerified,
            isPhoneVerified: userInfo.isPhoneVerified,
            streetAddressLine1: addressLine,
            streetAddressLine2,
            city,
            state,
            zip,
            county,
          },
        })
      ).unwrap();
      dispatch(clearUserInfo());
      await onPasskeyRegister();
      navigate("/orders");
    } catch (error) {
      toast.show({
        description: error.message || "Registration failed. Please try again.",
        ...toastErrorConfig,
      });
    }
  };

  return (
    <Box style={styles.wrapper}>
      <View style={styles.container}>
        <Text fontSize="2xl" bold textAlign={"center"}>
          Registration
        </Text>
        <FormControl>
          <FormControl.Label>Street Address Line 1</FormControl.Label>
          <View style={styles.dateButtonContainer}>
            <Company />
            <Autocomplete
              style={{
                height: 26,
                fontSize: 14,
                width: "100%",
                border: 0,
                backgroundColor: "#FAFAFA",
                paddingLeft: 14,
                outline: "none",
              }}
              apiKey={GOOGLE_API_KEY}
              placeholder="Street Address"
              value={addressLine || ""}
              onChange={(e) => setAddressLine(e.target.value)}
              onPlaceSelected={(details) => {
                const streetNumber = details.address_components.find(
                  (component) => component.types.includes("street_number")
                )?.long_name;
                const route = details.address_components.find((component) =>
                  component.types.includes("route")
                )?.long_name;
                const displayCity = details.address_components.filter(
                  (f) =>
                    JSON.stringify(f.types) ===
                    JSON.stringify(["locality", "political"])
                )[0]?.long_name;
                const displayState = details.address_components.filter(
                  (f) =>
                    JSON.stringify(f.types) ===
                    JSON.stringify(["administrative_area_level_1", "political"])
                )[0]?.long_name;

                const zip = details.address_components.filter(
                  (f) =>
                    JSON.stringify(f.types) === JSON.stringify(["postal_code"])
                )[0]?.long_name;
                const formattedStreetAddress = `${streetNumber || ""} ${
                  route || ""
                }`.trim();
                setStreetAddressLine1(details.formatted_address);
                setAddressLine(formattedStreetAddress);
                setCity(displayCity);
                setState(displayState);
                setZip(zip);
              }}
              options={{
                types: ["address"],
                componentRestrictions: { country: "us" },
              }}
            />
          </View>
          <View style={styles.divider} />
          <FormControl.Label>
            Street Address Line 2 (Apt, suit, other)
          </FormControl.Label>
          <Input
            value={streetAddressLine2}
            onChangeText={setStreetAddressLine2}
            type="text"
            InputLeftElement={
              <View style={styles.leftIcon}>
                <Buildings />
              </View>
            }
            placeholder="Street Address"
          />
          <View style={styles.divider} />
          <FormControl.Label>City</FormControl.Label>
          <Input
            value={city}
            onChangeText={setCity}
            type="text"
            InputLeftElement={
              <View style={styles.leftIcon}>
                <Geo />
              </View>
            }
            placeholder="City"
          />
          <FormControl.Label>State</FormControl.Label>
          <Select
            selectedValue={state}
            onValueChange={setState}
            InputLeftElement={
              <View style={styles.leftIcon}>
                <Map />
              </View>
            }
            minWidth="200"
            accessibilityLabel="State"
            placeholder="Registration State"
            mt="1"
          >
            {americanStates.map((name) => (
              <Select.Item key={name} label={name} value={name} />
            ))}
          </Select>
          <View style={styles.divider} />
          <FormControl.Label>ZIP Code</FormControl.Label>
          <Input
            value={zip}
            onChangeText={setZip}
            type="text"
            InputLeftElement={
              <View style={styles.leftIcon}>
                <Parcel />
              </View>
            }
            placeholder="ZIP Code"
          />
          <View style={styles.divider} />
          <FormControl.Label>County (Optional)</FormControl.Label>
          <Input
            value={county}
            onChangeText={setCounty}
            type="text"
            InputLeftElement={
              <View style={styles.leftIcon}>
                <World />
              </View>
            }
            placeholder="County"
          />
          <View style={styles.divider} />
        </FormControl>
        <Button style={styles.button} onPress={goToNextPage}>
          Continue
        </Button>
        <View style={styles.divider} />
      </View>
    </Box>
  );
}
