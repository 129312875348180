const styles = {
  container: {
    flex: 1,
    paddingHorizontal: 20,
    //  paddingTop: 14,
    paddingBottom: 20,
  },
  stepper: {
    alignItems: "center",
  },
  leftIcon: {
    marginLeft: 12,
  },
  ml16: {
    marginLeft: 16,
  },
  ml8: {
    marginLeft: 8,
  },
  ml4: {
    marginLeft: 4,
  },
  divider: {
    height: 16,
  },
  emptySpace: {
    height: 50,
  },
  addPhotoContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  addPhotoText: {
    fontSize: 16,
    fontWeight: "600",
    color: "#171717",
    marginLeft: 12,
  },
  row: {
    flexDirection: "row",
  },
  textContainer: {
    flexShrink: 1,
    marginLeft: 8,
  },
  image: {
    borderRadius: 8,
  },
  buttonsContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
  },
  greenScanButton: {
    borderRadius: 12,
    height: 44,
    width: 44,
    justifyContent: "center",
    alignItems: "center",
    flexShrink: 1,
  },
  vinRow: {
    flexDirection: "row",
    alignItems: "center",
  },
  searchButton: {
    backgroundColor: "#1DCE80",
    borderRadius: 8,
    padding: 5,
    marginRight: 6,
    width: 80,
  },
  searchButtonHover: {
    backgroundColor: "#17b36d",
  },
  searchButtonText: {
    color: "#FFFFFF",
    textAlign: "center",
  },
  h1: {
    fontSize: 24,
    fontWeight: "700",
    lineHeight: 30,
    marginBottom: 20,
    marginTop: 20,
  },
  inputsGrid: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    gap: 20,
  },
  inputGrid: {
    width: 300,
    justifyContent: "space-between",
  },
  dragImages: {
    borderWidth: 1,
    borderStyle: "dashed",
    borderRadius: 12,
    justifyContent: "center",
    alignItems: "center",
    borderColor: "#D4D4D4",
    padding: 24,
    textAlign: "center",
  },
  uploadText: {
    color: "#525252",
    fontWeight: "700",
    fontSize: 16,
  },
  supportedText: {
    color: "#737373",
    fontWeight: "500",
    fontSize: 12,
    marginTop: 10,
  },
  vehicleForm : {
    borderWidth: 1,
    borderColor: "#E5E5E5",
    borderRadius: 12,
    padding: 20,
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  },
};

export default styles;
