import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../config";
import {
  startRegistration,
  startAuthentication,
} from "@simplewebauthn/browser";
import instance from "../axios";

const handleRejectWithValue = (error, rejectWithValue) => {
  if (error.response) {
    // Return only serializable data
    return rejectWithValue({
      status: error.response.status,
      data: error.response.data,
    });
  }
  return rejectWithValue(error.message);
};

export const registerUser = createAsyncThunk(
  "auth/register",
  async ({ data }, { rejectWithValue }) => {
    try {
      const res = await axios.post(`${API_URL}/auth/register`, data);
      return res.data;
    } catch (error) {
      return rejectWithValue({
        data: error.response?.data?.message || "Registration failed",
      });
    }
  }
);

export const passkeyRegister = createAsyncThunk(
  "auth/passkeyRegister",
  async (_, { rejectWithValue }) => {
    try {
      let optionsJSON;
      try {
        // Fetch registration options from the backend
        const options = await instance.get(
          `${API_URL}/auth/webauthn/register`,
          { withCredentials: true }
        );
        optionsJSON = options.data;
      } catch (error) {
        return rejectWithValue(
          "Failed to fetch registration options from the server"
        );
      }

      let attestation;
      try {
        // Start WebAuthn registration
        attestation = await startRegistration({ optionsJSON });
      } catch (error) {
        // Handle user cancellation
        if (error.name === "NotAllowedError") {
          return rejectWithValue(
            "Passkey registration was canceled by the user"
          );
        }
        // Handle case where passkey already exists
        if (error.name === "InvalidStateError") {
          return rejectWithValue(
            "Passkey is already registered for this device"
          );
        }
        return rejectWithValue(
          error.message || "An unknown error occurred during registration"
        );
      }

      try {
        // Send the attestation response to the backend
        const result = await instance.post(
          `${API_URL}/auth/webauthn/register`,
          attestation,
          { withCredentials: true }
        );
        return result.data;
      } catch (error) {
        return rejectWithValue(
          "Failed to complete registration. Please try again"
        );
      }
    } catch (error) {
      return rejectWithValue("Unexpected error");
    }
  }
);

export const passkeyLogin = createAsyncThunk(
  "auth/passkeyLogin",
  async (_, { rejectWithValue }) => {
    let options;

    try {
      // Step 1: Get login options from the backend
      const optionsResponse = await axios.get(
        `${API_URL}/auth/webauthn/login`,
        { withCredentials: true }
      );
      options = optionsResponse.data;

      if (!options.allowCredentials || options.allowCredentials.length === 0) {
        return rejectWithValue("No credentials available for authentication");
      }
    } catch (error) {
      if (error.response?.status === 404) {
        return rejectWithValue("No registered passkey found for this account");
      }
      return rejectWithValue("Failed to fetch login options from the server");
    }

    let assertion;
    try {
      // Step 2: Start WebAuthn authentication on the frontend
      assertion = await startAuthentication({ optionsJSON: options });
    } catch (error) {
      // Handle user cancellation
      if (error.name === "NotAllowedError") {
        return rejectWithValue("Authentication was canceled by the user");
      }
      // Handle case where the device isn't registered
      if (error.name === "InvalidStateError") {
        return rejectWithValue("No registered passkey found for this device");
      }
      return rejectWithValue(
        error.message || "An unknown error occurred during authentication"
      );
    }

    try {
      // Step 3: Send the assertion to the backend for verification
      const result = await axios.post(
        `${API_URL}/auth/webauthn/login`,
        assertion,
        { withCredentials: true }
      );

      return result.data;
    } catch (error) {
      if (error.response?.status === 403) {
        return rejectWithValue(
          "This account has been deleted. Please contact support if you need assistance"
        );
      }
      return rejectWithValue("Failed to complete login. Please try again");
    }
  }
);

export const loginUser = createAsyncThunk(
  "auth/login",
  async ({ phone, email }, { rejectWithValue }) => {
    try {
      const res = await axios.post(`${API_URL}/auth/login`, {
        phone,
        email,
      });
      return res.data;
    } catch (error) {
      return rejectWithValue({
        data: error.response?.data?.message || "Login failed",
      });
    }
  }
);

export const loginUserByOtp = createAsyncThunk(
  "auth/otp",
  async ({ to, code }, { rejectWithValue }) => {
    try {
      const res = await axios.post(`${API_URL}/auth/otp`, {
        to,
        code,
      });
      return res.data;
    } catch (error) {
      return rejectWithValue({
        data: error.response?.data?.message || "Login by OTP failed",
      });
    }
  }
);

export const signUpAppleUser = createAsyncThunk(
  "auth/appleSignUp",
  async ({ data }, { rejectWithValue }) => {
    try {
      const res = await axios.post(`${API_URL}/auth/signup/apple/mobile`, data);
      return res.data;
    } catch (error) {
      return rejectWithValue({
        data: error.response?.data?.message || "Sign up with Apple failed",
      });
    }
  }
);

export const loginAppleUser = createAsyncThunk(
  "auth/appleLogin",
  async ({ data }, { rejectWithValue }) => {
    // return axios.post(`${API_URL}/auth/login`, {phone, email, password});
    try {
      const res = await axios.post(`${API_URL}/auth/login/apple/mobile`, data);
      return res.data;
    } catch (error) {
      return rejectWithValue({
        data: error.response?.data?.message || "Login with Apple failed",
      });
    }
  }
);

export const loginGoogleUser = createAsyncThunk(
  "auth/googleLogin",
  async ({ data }, { rejectWithValue }) => {
    try {
      const res = await axios.post(`${API_URL}/auth/login/google`, data);
      return res.data;
    } catch (error) {
      return rejectWithValue({
        data: error.response?.data?.message || "Login with Google failed",
      });
    }
  }
);

export const loginFacebookUser = createAsyncThunk(
  "auth/facebookLogin",
  async ({ data }, { rejectWithValue }) => {
    try {
      const res = await axios.post(
        `${API_URL}/auth/login/facebook/mobile`,
        data
      );
      return res.data;
    } catch (error) {
      return rejectWithValue({
        data: error.response?.data?.message || "Login with Facebook failed",
      });
    }
  }
);

export const checkUser = createAsyncThunk(
  "auth/checkUser",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axios.post(`${API_URL}/auth/check-user`, data);
      return res.data;
    } catch (error) {
      return rejectWithValue({
        data: error.response?.data?.message || "Failed to check user",
      });
    }
  }
);

export const verifyCredentialToEdit = createAsyncThunk(
  "auth/edit/verify-credential",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axios.put(
        `${API_URL}/verification/${data.dataType}`,
        data.verificationData
      );
      return res.data;
    } catch (error) {
      return handleRejectWithValue(error, rejectWithValue);
    }
  }
);

export const verifyCredential = createAsyncThunk(
  "auth/verify-credential",
  async (data, { rejectWithValue }) => {
    try {
      const res = await axios.post(
        `${API_URL}/verification/${data.dataType}`,
        data.verificationData
      );
      return res.data;
    } catch (error) {
      return handleRejectWithValue(error, rejectWithValue);
    }
  }
);
