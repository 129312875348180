// VehicleDetails.js
import React, { useState, useEffect } from "react";
import {
  Image,
  Modal,
  Pressable,
  Text,
  View,
  ScrollView,
  HStack,
  IconButton,
  ArrowBackIcon,
  ArrowForwardIcon,
  Divider,
} from "native-base";
import { imageHost } from "../../config";
import {
  Height,
  Length,
  TrailerClosed,
  TrailerOpen,
  VinGreen,
  Weight,
  Width,
} from "../../assets/images/svg";
import styles from "./styles";
import { Dimensions } from "react-native";

const VehicleDetails = React.memo(({ vehicle, index }) => {
  const [mainVehicleImage, setMainVehicleImage] = useState(
    vehicle.images[0] || null
  );
  const [imageSize, setImageSize] = useState({ width: 200, height: 200 }); // default size
  const [isModalOpen, setModalOpen] = useState(false); // Modal state
  const [modalImageIndex, setModalImageIndex] = useState(0); // Local index for modal
  const { width: windowWidth, height: windowHeight } = Dimensions.get("window");

  const openModal = (imgIndex) => {
    setModalImageIndex(imgIndex); // Set the local modal image index
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const nextModalImage = () => {
    setModalImageIndex((prevIndex) => (prevIndex + 1) % vehicle.images.length);
  };

  const prevModalImage = () => {
    setModalImageIndex(
      (prevIndex) =>
        (prevIndex - 1 + vehicle.images.length) % vehicle.images.length
    );
  };

  useEffect(() => {
    if (mainVehicleImage) {
      Image.getSize(
        imageHost + mainVehicleImage,
        (width, height) => {
          setImageSize({ width, height });
        },
        (error) => {
          console.log(error);
          setImageSize({ width: 200, height: 200 }); // Fallback size if there is an error
        }
      );
    }
  }, [mainVehicleImage, isModalOpen]);

  const modalSize = {
    maxWidth: windowWidth * 0.9,
    maxHeight: windowHeight * 0.9,
  };

  return (
    <View style={styles.vehicle} key={index}>
      <Text style={{ fontSize: 18, fontWeight: "bold", marginBottom: 5 }}>
        Vehicle # {index + 1}: {vehicle.year} {vehicle.brand} {vehicle.model}
      </Text>
      {/* <Text style={styles.header}>
        {vehicle.year} {vehicle.brand} {vehicle.model}
      </Text> */}
      <View>
        {!!mainVehicleImage && (
          <Pressable
            onPress={() => {
              const selectedIndex = vehicle.images.findIndex(
                (img) => img === mainVehicleImage
              );
              openModal(selectedIndex);
            }}
          >
            <Image
              source={{ uri: imageHost + mainVehicleImage }}
              alt={`Main Image of Vehicle ${index}`}
              style={styles.image}
              height={200}
            />
          </Pressable>
        )}
        <ScrollView
          horizontal
          // showsHorizontalScrollIndicator={false}
          style={styles.imagesRow}
        >
          {vehicle.images.map((image, imgIndex) => (
            <Pressable
              key={imgIndex}
              onPress={() => setMainVehicleImage(image)}
            >
              <Image
                source={{ uri: imageHost + image }}
                alt={`Thumbnail of Vehicle ${index} Image ${imgIndex}`}
                style={[
                  styles.imageSmall,
                  mainVehicleImage === image && styles.activeImage,
                ]}
              />
            </Pressable>
          ))}
        </ScrollView>
      </View>
      <View style={styles.badges}>
        <View mr={"4px"} ml={"4px"} style={styles.badge}>
          <Weight />
          <Text style={styles.badgeText}>{vehicle.weight}</Text>
        </View>
        <View mr={"4px"} style={styles.badge}>
          <Length />
          <Text style={styles.badgeText}>{vehicle.length}</Text>
        </View>
        <View mr={"4px"} ml={"4px"} style={styles.badge}>
          <Height />
          <Text style={styles.badgeText}>{vehicle.height}</Text>
        </View>
        <View ml={"4px"} style={styles.badge}>
          <Width />
          <Text style={styles.badgeText}>{vehicle.width}</Text>
        </View>
      </View>
      <View shadow="3" style={styles.vinBadge}>
        <VinGreen />
        <View style={styles.vinText}>
          <Text style={styles.greyText}>VIN</Text>
          <Text>{vehicle.vin}</Text>
        </View>
      </View>
      {!!vehicle.additionalEquipment?.length && (
        <>
          <Text style={styles.headerBlock}>Additional equipment</Text>
          <Text>{vehicle.additionalEquipment}</Text>
        </>
      )}
      {!!vehicle.customChanges?.length && (
        <>
          <Text style={styles.headerBlock}>Car has custom changes/tuning</Text>
          <Text>{vehicle.customChanges}</Text>
        </>
      )}
      {!!vehicle.personalItems?.length && (
        <>
          <Text style={styles.headerBlock}>Personal items</Text>
          <Text>{vehicle.personalItems}</Text>
        </>
      )}
      <View>
        <Text style={styles.headerBlock}>Trailer Type</Text>
        {vehicle.isTrailerEnclosed ? (
          <View style={styles.row}>
            <TrailerClosed />
            <Text style={styles.ml4} fontSize="sm">
              Enclosed Trailer
            </Text>
          </View>
        ) : (
          <View style={styles.row}>
            <TrailerOpen />
            <Text style={styles.ml4} fontSize="sm">
              Open Trailer
            </Text>
          </View>
        )}

        <Text style={styles.headerBlock}>Is car Operable?</Text>
        <Text>{vehicle.isInoperable ? "No" : "Yes"}</Text>
      </View>
      {isModalOpen && (
        <Modal
          isOpen={isModalOpen}
          onClose={closeModal}
          size="lg"
          useRNModal={true}
        >
          <Modal.Content
            maxWidth={windowWidth * 0.9}
            maxHeight={windowHeight * 0.9}
          >
            <Modal.CloseButton />
            <View
              style={{
                flex: 1,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <IconButton
                icon={<ArrowBackIcon />}
                onPress={prevModalImage}
                variant="ghost"
                isDisabled={modalImageIndex === 0}
                size="lg"
              />
              <Image
                source={{ uri: imageHost + vehicle.images[modalImageIndex] }}
                alt={`Image ${modalImageIndex + 1}`}
                style={{
                  flexShrink: 1,
                  width: imageSize.width,
                  height: imageSize.height,
                  maxWidth: windowWidth * 0.6, // adjust width for space
                  maxHeight: windowHeight * 0.6, // adjust height for space
                  borderRadius: 12,
                }}
                resizeMode="contain"
              />
              <IconButton
                icon={<ArrowForwardIcon />}
                onPress={nextModalImage}
                variant="ghost"
                isDisabled={modalImageIndex === vehicle.images.length - 1}
                size="lg"
              />
            </View>
          </Modal.Content>
        </Modal>
      )}
    </View>
  );
});

export default VehicleDetails;
