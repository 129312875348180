import * as React from "react";
import styles from "./styles";
import { Menu, Pressable, Text, Box, View, useToast } from "native-base";
import { ArrowDown } from "../../assets/images/svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { passkeyRegister } from "../../networking/auth/authApi";
import { toastErrorConfig, toastSuccessConfig } from "../../config/constants";
import { browserSupportsWebAuthn } from "@simplewebauthn/browser";
import { setWebauthnSuccess } from "../../networking/auth/authSlice";
import { globalLogout } from "../../networking/globalActions";

export default function UserProfileButton({ name }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();
  const userId = useSelector((state) => state.profile.id);
  const webauthnSuccess = useSelector((state) => state.auth.webauthnSuccess);

  const onPasskeyRegister = async () => {
    try {
      await dispatch(passkeyRegister()).unwrap();
      toast.show({
        description: "Passkey registration successful",
        ...toastSuccessConfig,
      });
    } catch (error) {
      if (error === "Passkey is already registered for this device") {
        dispatch(setWebauthnSuccess(true));
      }
      {
        toast.show({
          description: error || "Passkey registration failed",
          ...toastErrorConfig,
        });
      }
    }
  };

  const getInitials = (name) => {
    const words = name.split(" ");
    let initials = "";
    words.forEach((word, index) => {
      if (index === 0 || index === words.length - 1) {
        initials += word.charAt(0).toUpperCase();
      }
    });
    return initials;
  };
  const logOut = () => {
    dispatch(globalLogout());
    navigate("/");
  };
  const initials = getInitials(name || "");
  return (
    <Box alignItems="center">
      <Menu
        placement={"bottom right"}
        w="188"
        trigger={(triggerProps) => {
          return (
            <Pressable accessibilityLabel="More options menu" {...triggerProps}>
              <View style={styles.userProfileButtonWrapper}>
                <View style={styles.userAvatarWrapper}>
                  <Text style={styles.userAvatarText}>{initials}</Text>
                </View>
                <View>
                  <ArrowDown />
                </View>
              </View>
            </Pressable>
          );
        }}
      >
        <Text style={styles.menuName}>{name}</Text>
        <Menu.Item
          onPress={() => {
            navigate("/profile");
          }}
        >
          My profile
        </Menu.Item>
        {browserSupportsWebAuthn() && (
          <Menu.Item
            onPress={!webauthnSuccess && onPasskeyRegister}
            isDisabled={webauthnSuccess}
            style={webauthnSuccess ? styles.disabledItem : {}}
          >
            Register Passkey
          </Menu.Item>
        )}
        <Menu.Item onPress={logOut}>Log out</Menu.Item>
      </Menu>
    </Box>
  );
}
