import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 48,
  //  paddingTop: 20,
    paddingBottom: 20,
  },
  stepper: {
    alignItems: "center",
  },
  divider: {
    height: 16,
  },
  emptySpace: {
    height: 50,
  },
  date: {
    flex: 1,
    marginLeft: 5,
    marginRight: 5,
  },
  dateButtonContainer: {
    flex: 1,
    marginLeft: 5,
    marginRight: 5,
    borderWidth: 1,
    borderRadius: 12,
    borderColor: "#E5E5E5",
    flexDirection: "row",
    alignItems: "center",
    padding: 12,
  },
  dateButtonText: {
    color: "#A3A3A3",
    fontSize: 16,
    marginLeft: 8,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 10,
  },
  textContainer: {
    flexShrink: 1,
    marginLeft: 8,
  },
  inputBorder: {
    borderWidth: 1,
    borderRadius: 12,
    borderColor: "#E5E5E5",
    padding: 12,
  },
  checkbox: {
    paddingLeft: 10,
    flexDirection: "row"
  },
  h1: {
    fontSize: 24,
    fontWeight: "700",
    lineHeight: 30,
    marginBottom: 20,
    marginTop: 20,
  },
});

export default styles;
