export const formatStringToDate = (dateString) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const date = new Date(dateString + "T00:00:00Z");
  const month = months[date.getUTCMonth()];
  const day = date.getUTCDate();
  const year = date.getUTCFullYear();

  return `${month} ${day}, ${year}`;
};

const isFeeApplied = (dateString) => {
  // Create a date object with the local time by splitting and parsing the date string
  const [year, month, day] = dateString.split("-").map(Number);

  const breakPointTime = new Date(Date.UTC(year, month - 1, day, 17, 0, 0)); // UTC 17:00 is 9:00 AM in Los Angeles time

  const todayDate = new Date(
    new Intl.DateTimeFormat("en-US", {
      timeZone: "America/Los_Angeles",
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: false,
    }).format(new Date())
  );

  const todayPlus24Hours = new Date(todayDate.getTime() + 24 * 60 * 60 * 1000);

  console.log("breakPointTime: ", breakPointTime);
  console.log("todayDate: ", todayDate);
  console.log("todayPlus24Hours: ", todayPlus24Hours);

  return todayPlus24Hours > breakPointTime;
};

export default isFeeApplied;
