import * as React from "react";
import Svg, { Path } from "react-native-svg";

function AddressGrey(props) {
  return (
    <Svg
      width={20}
      height={20}
      viewBox="167 5279 14 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M174,5286.219 C174,5287.324 173.105,5288.219 172,5288.219 C170.895,5288.219 170,5287.324 170,5286.219 C170,5285.114 170.895,5284.219 172,5284.219 C173.105,5284.219 174,5285.114 174,5286.219 M172,5296 C172,5296 167,5289 167,5286 C167,5283.243 169.243,5281 172,5281 C174.757,5281 177,5283.243 177,5286 C177,5289 172,5296 172,5296 M172,5279 C168.134,5279 165,5282.134 165,5286 C165,5289.866 172,5299 172,5299 C172,5299 179,5289.866 179,5286 C179,5282.134 175.866,5279 172,5279"
        fill="#A3A3A3" // Solid grey fill
        stroke="none" // Removed the stroke for a solid appearance
      />
    </Svg>
  );
}

export default AddressGrey;