import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 48,
    //  paddingTop: 20,
    paddingBottom: 40,
  },
  stepper: {
    alignItems: "center",
  },
  divider: {
    height: 16,
  },
  emptySpace: {
    height: 50,
  },
  leftIcon: {
    marginLeft: 12,
  },
  row: {
    flexDirection: "row",
    marginTop: 16,
    marginBottom: 16,
  },
  textContainer: {
    flexShrink: 1,
    marginLeft: 8,
    color: "#525252",
    fontSize: 14,
  },
  h1: {
    fontSize: 24,
    fontWeight: "700",
    lineHeight: 30,
    marginBottom: 20,
    marginTop: 20,
  },
  dateButtonContainer: {
    flex: 1,
    marginRight: 5,
    borderWidth: 1,
    borderRadius: 12,
    borderColor: "#E5E5E5",
    flexDirection: "row",
    paddingTop: 12,
    paddingRight: 12,
    paddingLeft: 12,
    paddingBottom: 4,
  },
  cancel: {
    flex: 1,
    marginRight: 3,
    width: 116,
  },
  dcButton: {
    width: 116,
    flex: 1,
    marginLeft: 3,
  },
  buttonsRow: {
    flexDirection: "row",
    flex: 2,
  },
  cancelButton: {
    flex: 1,
    borderWidth: 1,
    borderColor: "#000",
    marginRight: 3,
  },
  confirmButton: {
    flex: 1,
    marginLeft: 3,
  },
  dropdownContainer: {
    position: "absolute",
    width: "50%",
    backgroundColor: "white",
    zIndex: 1000,
    marginTop: 110,
    marginLeft: 10,
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)", // Soft shadow for depth
  },
  dropdownItem: {
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: "#ccc",
  },
});

export default styles;
