import * as React from "react";
import {
  Center,
  Checkbox,
  FormControl,
  Input,
  Button,
  Select,
  Spinner,
  Text,
  TextArea,
  View,
  useToast,
  ScrollView,
  Pressable,
} from "native-base";
import styles from "./styles";
import { useDispatch, useSelector } from "react-redux";
import {
  setInputValue,
  clearInputValues,
} from "../../../networking/orders/ordersSlice";
import {
  Vin,
  Model,
  Year,
  Length,
  Height,
  Width,
  Weight,
  Upload,
} from "../../../assets/images/svg";
import { carsList, carsObj } from "../../../assets/data/carsList";
import { useEffect, useState } from "react";
import instance from "../../../networking/axios";
import { API_URL } from "../../../config";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import BottomNavigator from "../../../components/BottomNavigator";
import ReactImageUploading from "react-images-uploading";
import { toastErrorConfig } from "../../../config/constants";
import { imageHost } from "../../../config";
import Stepper from "../../../components/stepper";
import { getInfoByVinNumber } from "../../../networking/orders/ordersApi";
import { getPlaces } from "../../../networking/orders/ordersApi";

export default function NOFFirstStepScreen() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const orderState = useSelector((state) => state.order);
  const toast = useToast();
  const [models, setModels] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [isSearchButtonHovered, setIsSearchButtonHovered] = useState(false);
  const { orders, active } = useSelector((state) => state.profile);
  const profileOrder = orders.find((order) => order.id === id) || null;
  const defaultVehicle = {
    vin: "",
    brand: "",
    model: "",
    year: "",
    height: "",
    length: "",
    width: "",
    weight: "",
    isInoperable: false,
    isTrailerEnclosed: false,
    images: [],
    personalItems: "",
    havePersonal: false,
    hasCustom: false,
    customChanges: "",
    additionalEquipment: "",
  };
  const [localInputs, setLocalInputs] = useState({
    vehicles:
      orderState.vehicles && orderState.vehicles.length > 0
        ? orderState.vehicles
        : [defaultVehicle],
  });

  useEffect(() => {
    if (!active) {
      navigate("/orders");
    }
    if (profileOrder) {
      if (profileOrder.status === "pickedUp") {
        navigate(
          location.pathname.includes("request-edit")
            ? `/request-edit/2/${id}`
            : id
            ? `/edit/2/${id}`
            : "/create/2"
        );
        return;
      }
    }
    if (
      profileOrder &&
      profileOrder.vehicles.length > 0 &&
      !orderState.isFetched
    ) {
      dispatch(clearInputValues());

      // Set general order fields
      Object.entries(profileOrder).forEach(([key, value]) => {
        if (key !== "vehicles") {
          dispatch(setInputValue({ key, value }));
        }
      });

      setLocalInputs({
        vehicles: profileOrder.vehicles.map((vehicle) => ({
          ...defaultVehicle, // ensures all fields are initialized
          ...vehicle, // spreads existing vehicle data
        })),
      });
      dispatch(setInputValue({ key: "isFetched", value: true }));
    }
  }, [id, orders]);

  const handleChangeVehicleDetail = (index, key, value) => {
    setLocalInputs((prevInputs) => {
      const updatedVehicles = [...prevInputs.vehicles]; // Create a new array
      const updatedVehicle = { ...updatedVehicles[index] }; // Create a new object for the specific vehicle
      updatedVehicle[key] = value; // Update the property
      updatedVehicles[index] = updatedVehicle; // Replace the old vehicle object

      return { ...prevInputs, vehicles: updatedVehicles }; // Return new state
    });
  };

  const createYearArray = () => {
    const currentYear = new Date().getFullYear() + 1; // make sure we can add cars from the next year
    const startYear = 1900;
    const yearArray = [];
    for (let year = startYear; year <= currentYear; year++) {
      yearArray.push(year.toString());
    }

    return yearArray.reverse();
  };

  const years = createYearArray();

  const validateVehicles = () => {
    for (let i = 0; i < localInputs.vehicles.length; i++) {
      const vehicle = localInputs.vehicles[i];

      if (!vehicle.vin || vehicle.vin.length !== 17) {
        toast.show({
          description: `Please fill in correct VIN for vehicle #${i + 1}`,
          ...toastErrorConfig,
        });
        return false;
      }
      if (!vehicle.brand) {
        toast.show({
          description: `Please fill in Make for vehicle #${i + 1}`,
          ...toastErrorConfig,
        });
        return false;
      }
      if (!vehicle.model) {
        toast.show({
          description: `Please fill in Model for vehicle #${i + 1}`,
          ...toastErrorConfig,
        });
        return false;
      }
      if (!vehicle.year) {
        toast.show({
          description: `Please fill in Year for vehicle #${i + 1}`,
          ...toastErrorConfig,
        });
        return false;
      }
      if (vehicle.images.length === 0) {
        toast.show({
          description: `Please upload at least one image for vehicle #${i + 1}`,
          ...toastErrorConfig,
        });
        return false;
      }
    }
    return true; // All checks passed
  };

  const nextStep = () => {
    if (validateVehicles()) {
      console.log("localInputs", localInputs);

      // Dispatch local state to Redux
      Object.entries(localInputs).forEach(([key, value]) => {
        dispatch(setInputValue({ key, value }));
      });

      navigate(
        location.pathname.includes("request-edit")
          ? `/request-edit/2/${id}`
          : id
          ? `/edit/2/${id}`
          : "/create/2"
      );
    }
  };

  const cancel = () => {
    navigate("/orders");
  };

  const onChange = async (imageList, index) => {
    // Use Promise.all to upload images in parallel

    const uploadPromises = imageList
      .filter((image) => typeof image !== "string") // Ignore already-uploaded files
      .map((image) => uploadResource(image)); // Only upload new files
    try {
      const uploadedImages = await Promise.all(uploadPromises);

      const newImages = uploadedImages.filter((img) => img !== null);

      const updatedImages = [
        ...localInputs.vehicles[index].images,
        ...newImages,
      ];
      handleChangeVehicleDetail(index, "images", updatedImages);
    } catch (error) {
      console.error("Error uploading multiple images: ", error);
    }
  };

  const uploadResource = async ({ file }) => {
    try {
      const formattedFileName = file.name.replace(/\s+/g, "-"); // Format the file name

      const formData = new FormData();
      formData.append("file", file, formattedFileName);

      const result = await instance.post(
        `${API_URL}/api/v1/orders/upload`,
        formData
      );

      if (result?.data?.fileName) {
        // Return the full image URL for Redux state update
        return `${result.data.fileName}`;
      }
      return null;
    } catch (error) {
      console.error("Error uploading image: ", error);
      return null; // Return null to indicate failure
    }
  };

  const getByVin = async (vin, index) => {
    if (vin?.length < 17 || vin?.length > 17) {
      toast.show({
        description: "VIN is invalid",
        ...toastErrorConfig,
      });
      return;
    }
    setShowLoader(true);
    try {
      const res = await dispatch(getInfoByVinNumber({ vin })).unwrap();

      if (res) {
        handleChangeVehicleDetail(index, "brand", res.brand || "");
        handleChangeVehicleDetail(index, "model", res.model || "");
        handleChangeVehicleDetail(index, "year", res.year || "");
        handleChangeVehicleDetail(index, "height", res.height || "");
        handleChangeVehicleDetail(index, "width", res.width || "");
        handleChangeVehicleDetail(index, "weight", res.weight || "");
        handleChangeVehicleDetail(index, "length", res.length || "");
      }
    } catch (error) {
      toast.show({
        description: "Unable to retrieve car info using the provided VIN",
        ...toastErrorConfig,
      });
      setShowLoader(false);
      return;
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    dispatch(getPlaces())
      .unwrap()
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  const validateSingleVehicle = (vehicle, index) => {
    if (!vehicle.vin || vehicle.vin.length !== 17) {
      toast.show({
        description: `Please fill in correct VIN for vehicle #${index + 1}`,
        ...toastErrorConfig,
      });
      return false;
    }
    if (!vehicle.brand) {
      toast.show({
        description: `Please fill in Make for vehicle #${index + 1}`,
        ...toastErrorConfig,
      });
      return false;
    }
    if (!vehicle.model) {
      toast.show({
        description: `Please fill in Model for vehicle #${index + 1}`,
        ...toastErrorConfig,
      });
      return false;
    }
    if (!vehicle.year) {
      toast.show({
        description: `Please fill in Year for vehicle #${index + 1}`,
        ...toastErrorConfig,
      });
      return false;
    }
    if (vehicle.images.length === 0) {
      toast.show({
        description: `Please upload at least one image for vehicle #${
          index + 1
        }`,
        ...toastErrorConfig,
      });
      return false;
    }
    // Add more checks as needed for other properties
    return true;
  };

  const addVehicle = () => {
    const lastVehicle = localInputs.vehicles[localInputs.vehicles.length - 1];

    if (validateSingleVehicle(lastVehicle, localInputs.vehicles.length - 1)) {
      setLocalInputs((prevInputs) => ({
        ...prevInputs,
        vehicles: [...prevInputs.vehicles, { ...defaultVehicle }],
      }));
    }
  };

  const removeVehicle = (index) => {
    setLocalInputs((prevInputs) => {
      // Check if the first vehicle is being removed and it's the only one
      if (index === 0 && prevInputs.vehicles.length === 1) {
        return {
          ...prevInputs,
          vehicles: [defaultVehicle], // Reset to default state
        };
      } else {
        return {
          ...prevInputs,
          vehicles: prevInputs.vehicles.filter((_, i) => i !== index),
        };
      }
    });
  };

  return (
    <View style={styles.container}>
      <Text style={styles.h1}>
        {location.pathname.includes("request-edit")
          ? "Request edit"
          : id
          ? "Edit order"
          : "Create order"}
      </Text>
      <View style={styles.stepper}>
        <Stepper currentStep={1} />
      </View>
      <View>
        {showLoader && (
          <View
            style={{
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              position: "absolute",
              width: "100%",
              height: "100%",
              zIndex: 10,
              paddingTop: 200,
            }}
          >
            <Spinner size="lg" />
          </View>
        )}
        <View style={styles.divider} />
        {localInputs.vehicles.map((vehicle, index) => (
          <View>
            <View style={styles.vehicleForm} key={index}>
              <Text
                style={{ fontSize: 18, fontWeight: "bold", marginBottom: 5 }}
              >
                Vehicle # {index + 1}
              </Text>
              <View style={styles.inputsGrid}>
                <View style={styles.inputGrid}>
                  <FormControl.Label
                    style={{
                      marginBottom: 0,
                      paddingBottom: 0,
                    }}
                  >
                    VIN
                  </FormControl.Label>
                  <View>
                    <Input
                      isDisabled={location.pathname.includes("request-edit")}
                      autoCapitalize={"characters"}
                      type="text"
                      value={vehicle.vin}
                      onChangeText={(value) => {
                        const sanitizedValue = value
                          .replace(/[^A-Z0-9]/gi, "")
                          .toUpperCase();
                        handleChangeVehicleDetail(index, "vin", sanitizedValue);
                      }}
                      InputLeftElement={
                        <View style={styles.leftIcon}>
                          <Vin />
                        </View>
                      }
                      InputRightElement={
                        <View
                          onMouseEnter={() => setIsSearchButtonHovered(true)}
                          onMouseLeave={() => setIsSearchButtonHovered(false)}
                          style={[
                            styles.searchButton,
                            isSearchButtonHovered && styles.searchButtonHover,
                          ]}
                        >
                          <Pressable
                            isDisabled={location.pathname.includes(
                              "request-edit"
                            )}
                            onPress={() => getByVin(vehicle.vin, index)}
                          >
                            <Text style={styles.searchButtonText}>Search</Text>
                          </Pressable>
                        </View>
                      }
                      placeholder="VIN"
                    />
                  </View>
                </View>
                <View style={styles.inputGrid}>
                  <FormControl.Label>Make</FormControl.Label>
                  <Select
                    isDisabled={location.pathname.includes("request-edit")}
                    InputLeftElement={
                      <View style={styles.leftIcon}>
                        <Model />
                      </View>
                    }
                    selectedValue={vehicle.brand}
                    onValueChange={(value) => {
                      setModels(carsObj[value]);
                      handleChangeVehicleDetail(index, "brand", value);
                    }}
                    minWidth="200"
                    accessibilityLabel="brand"
                    placeholder="Select"
                    mt="1"
                  >
                    {carsList.map((car) => (
                      <Select.Item key={car} label={car} value={car} />
                    ))}
                  </Select>
                </View>
                <View style={styles.inputGrid}>
                  <FormControl.Label>Model</FormControl.Label>
                  <Select
                    isDisabled={
                      !vehicle.brand ||
                      location.pathname.includes("request-edit")
                    }
                    onValueChange={(value) => {
                      handleChangeVehicleDetail(index, "model", value);
                    }}
                    selectedValue={vehicle.model}
                    InputLeftElement={
                      <View style={styles.leftIcon}>
                        <Model />
                      </View>
                    }
                    minWidth="200"
                    accessibilityLabel="models"
                    placeholder="Select"
                    mt="1"
                  >
                    {models.map((model) => (
                      <Select.Item key={model} label={model} value={model} />
                    ))}
                    {models.length === 0 && !!vehicle.model && (
                      <Select.Item
                        key={vehicle.model}
                        label={vehicle.model}
                        value={vehicle.model}
                      />
                    )}
                  </Select>
                </View>
                <View style={styles.inputGrid}>
                  <FormControl.Label>Year</FormControl.Label>
                  <Select
                    isDisabled={location.pathname.includes("request-edit")}
                    InputLeftElement={
                      <View style={styles.leftIcon}>
                        <Year />
                      </View>
                    }
                    selectedValue={vehicle.year.toString()}
                    onValueChange={(value) => {
                      handleChangeVehicleDetail(
                        index,
                        "year",
                        value.toString()
                      );
                    }}
                    minWidth="200"
                    accessibilityLabel="year"
                    placeholder="Select"
                    mt="1"
                  >
                    {years.map((year) => (
                      <Select.Item key={year} label={year} value={year} />
                    ))}
                  </Select>
                </View>
                <View style={styles.inputGrid}>
                  <FormControl.Label>Weight</FormControl.Label>
                  <Input
                    isDisabled={location.pathname.includes("request-edit")}
                    value={vehicle.weight}
                    onChangeText={(value) => {
                      handleChangeVehicleDetail(index, "weight", value);
                    }}
                    type="text"
                    InputLeftElement={
                      <View style={styles.leftIcon}>
                        <Weight />
                      </View>
                    }
                    placeholder="Enter weight"
                  />
                </View>
                <View style={styles.inputGrid}>
                  <FormControl.Label>Length</FormControl.Label>
                  <Input
                    isDisabled={location.pathname.includes("request-edit")}
                    value={vehicle.length}
                    onChangeText={(value) => {
                      handleChangeVehicleDetail(index, "length", value);
                    }}
                    type="text"
                    InputLeftElement={
                      <View style={styles.leftIcon}>
                        <Length />
                      </View>
                    }
                    placeholder="Enter length"
                  />
                </View>
                <View style={styles.inputGrid}>
                  <FormControl.Label>Height</FormControl.Label>
                  <Input
                    isDisabled={location.pathname.includes("request-edit")}
                    value={vehicle.height}
                    onChangeText={(value) => {
                      handleChangeVehicleDetail(index, "height", value);
                    }}
                    type="text"
                    InputLeftElement={
                      <View style={styles.leftIcon}>
                        <Height />
                      </View>
                    }
                    placeholder="Enter height"
                  />
                </View>
                <View style={styles.inputGrid}>
                  <FormControl.Label>Width</FormControl.Label>
                  <Input
                    isDisabled={location.pathname.includes("request-edit")}
                    value={vehicle.width}
                    onChangeText={(value) => {
                      handleChangeVehicleDetail(index, "width", value);
                    }}
                    type="text"
                    InputLeftElement={
                      <View style={styles.leftIcon}>
                        <Width />
                      </View>
                    }
                    placeholder="Enter width"
                  />
                </View>
              </View>
              <View style={styles.divider} />
              <View style={styles.row}>
                <Checkbox
                  value="checkbox"
                  isChecked={!vehicle.isInoperable}
                  onChange={(value) =>
                    handleChangeVehicleDetail(index, "isInoperable", value)
                  }
                  accessibilityLabel="checkbox"
                />
                <Text style={styles.textContainer} fontSize="sm">
                  Is car Operable?
                </Text>
              </View>
              <View style={styles.divider} />
              <View style={styles.row}>
                <Checkbox
                  value="checkbox"
                  defaultIsChecked={vehicle.isTrailerEnclosed}
                  onChange={(value) =>
                    handleChangeVehicleDetail(index, "isTrailerEnclosed", value)
                  }
                  accessibilityLabel="checkbox"
                />
                <Text style={styles.textContainer} fontSize="sm">
                  Do you need enslosed Trailer?
                </Text>
              </View>
              <View style={styles.divider} />
              <FormControl.Label>Additional equipment</FormControl.Label>
              <TextArea
                value={vehicle.additionalEquipment}
                onChangeText={(value) => {
                  handleChangeVehicleDetail(
                    index,
                    "additionalEquipment",
                    value
                  );
                }}
                numberOfLines={2}
                placeholder="Enter additional equipment"
              />
              <View style={styles.divider} />
              <>
                <View style={styles.row}>
                  <Center>
                    <Checkbox
                      value="checkbox"
                      isChecked={vehicle.hasCustom}
                      onChange={(value) => {
                        handleChangeVehicleDetail(index, "hasCustom", value);
                        if (!value) {
                          handleChangeVehicleDetail(index, "customChanges", "");
                        }
                      }}
                      accessibilityLabel="checkbox"
                    />
                  </Center>

                  <Text style={styles.textContainer} fontSize="sm">
                    Car has custom changes/tuning
                  </Text>
                </View>
                {vehicle.hasCustom && (
                  <>
                    <View style={styles.divider} />
                    <TextArea
                      value={vehicle.customChanges}
                      onChangeText={(value) => {
                        handleChangeVehicleDetail(
                          index,
                          "customChanges",
                          value
                        );
                      }}
                      numberOfLines={2}
                      placeholder="Please, describe the changes added in the construction"
                    />
                  </>
                )}
              </>
              <View style={styles.divider} />
              <>
                <View style={styles.row}>
                  <Center>
                    <Checkbox
                      value="checkbox"
                      isChecked={vehicle.havePersonal}
                      onChange={(value) => {
                        handleChangeVehicleDetail(index, "havePersonal", value);
                        if (!value) {
                          handleChangeVehicleDetail(index, "personalItems", "");
                        }
                      }}
                      accessibilityLabel="checkbox"
                    />
                  </Center>

                  <Text style={styles.textContainer} fontSize="sm">
                    I have personal items in my car
                  </Text>
                </View>
                {vehicle.havePersonal && (
                  <>
                    <View style={styles.divider} />
                    <TextArea
                      value={vehicle.personalItems}
                      onChangeText={(value) => {
                        handleChangeVehicleDetail(
                          index,
                          "personalItems",
                          value
                        );
                      }}
                      numberOfLines={2}
                      placeholder="Please, list the items to be transported and indicate their total weight"
                    />
                  </>
                )}
              </>
              <View style={styles.divider} />
              <ReactImageUploading
                multiple
                value={vehicle.images}
                onChange={(imageList) => onChange(imageList, index)}
                maxNumber={10}
                dataURLKey="data_url"
              >
                {({
                  imageList,
                  onImageUpload,
                  onImageRemoveAll,
                  onImageUpdate,
                  onImageRemove,
                  isDragging,
                  dragProps,
                }) => (
                  <div className="upload__image-wrapper">
                    <View style={{ marginBottom: 10 }}>
                      <ScrollView
                        horizontal
                        showsHorizontalScrollIndicator={false}
                        style={{ flexDirection: "row" }}
                      >
                        {imageList.map((image, index) => {
                          const imageUrl = `${image.fileName || image}`;
                          return (
                            <div
                              key={index}
                              style={{ marginTop: 10, marginRight: 10 }}
                            >
                              <img
                                src={`${imageHost}${imageUrl}`}
                                alt={`uploaded-${index}`}
                                width="100"
                                height="100"
                                style={{
                                  width: "200px",
                                  height: "200px",
                                  objectFit: "cover",
                                  borderRadius: 12,
                                  filter: "blur(0.5px)", // Helps with display on smaller resolutions
                                }}
                              />
                              <div style={{ textAlign: "center" }}>
                                <button
                                  onClick={() => {
                                    const newImageList = vehicle.images.filter(
                                      (_, i) => i !== index
                                    ); // Filter out the removed image
                                    handleChangeVehicleDetail(
                                      index,
                                      "images",
                                      newImageList
                                    );
                                  }}
                                >
                                  Remove
                                </button>
                              </div>
                            </div>
                          );
                        })}
                      </ScrollView>
                    </View>
                    <div
                      style={styles.dragImages}
                      onClick={onImageUpload}
                      {...dragProps}
                    >
                      <Upload />
                      <div style={styles.uploadText}>
                        Drag & drop files or Browse
                      </div>
                      <div style={styles.supportedText}>
                        Supported formats: JPEG, PNG
                      </div>
                    </div>
                  </div>
                )}
              </ReactImageUploading>

              <View style={styles.divider} />
              <View style={{ width: 127 }}>
                <Button
                  isDisabled={
                    location.pathname.includes("request-edit") || !active
                  }
                  onPress={() => removeVehicle(index)}
                  variant="outline"
                >
                  Remove Vehicle
                </Button>
              </View>
            </View>
            <View style={styles.divider} />
          </View>
        ))}
        {localInputs.vehicles.length < 10 && (
          <View style={{ width: 127 }}>
            <Button
              isDisabled={location.pathname.includes("request-edit") || !active}
              onPress={addVehicle}
            >
              Add Vehicle
            </Button>
          </View>
        )}
        <View style={styles.divider} />
        <View style={styles.emptySpace} />
        <BottomNavigator
          nextOnPress={nextStep}
          nextText={"Next"}
          prevOnPress={cancel}
          prevText={"Cancel"}
        />
      </View>
    </View>
  );
}
