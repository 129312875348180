import * as React from "react";
import { Box, Button, Text, View, HStack, useToast, Center } from "native-base";
import { useDispatch, useSelector } from "react-redux";
import styles from "./styles";
import { useEffect, useState } from "react";
import {
  // Apple,
  // Facebook,
  Google,
  // Logo
} from "../../assets/images/svg";

// import {appleAuth} from '@invertase/react-native-apple-authentication';

// import {
//   GoogleSignin,
//   GoogleSigninButton,
//   statusCodes,
// } from '@react-native-google-signin/google-signin';

import // loginAppleUser,
// loginFacebookUser,
// loginGoogleUser,
// signUpAppleUser,
"../../networking/auth/authApi";
// import { setUser } from "../../networking/auth/authSlice";
import { useLocation, useNavigate } from "react-router-dom";
import {
  isMobile,
  toastErrorConfig,
  toastSuccessConfig,
} from "../../config/constants";
import { GoogleLogin } from "@react-oauth/google";
import { checkUser, loginGoogleUser } from "../../networking/auth/authApi";
import { setUser, clearErrorMessage } from "../../networking/auth/authSlice";
import { jwtDecode } from "jwt-decode";
import { passkeyLogin } from "../../networking/auth/authApi";
import { browserSupportsWebAuthn } from "@simplewebauthn/browser";

// GoogleSignin.configure({
//   webClientId:
//     Platform.OS === 'ios'
//       ? '521885662516-a520u4v7u4ur8n8s33l0880s2bbar4le.apps.googleusercontent.com'
//       : '219991079029-5bmtc67cioh4nosij9pjnvdbbvr6olu8.apps.googleusercontent.com',
//   // webClientId:
//   //   '219991079029-5bmtc67cioh4nosij9pjnvdbbvr6olu8.apps.googleusercontent.com',
//   // client ID of type WEB for your server (needed to verify user ID and offline access)
//   offlineAccess: true, // if you want to access Google API on behalf of the user FROM YOUR SERVER
//   forceCodeForRefreshToken: true, // [Android] related to `serverAuthCode`, read the docs link below *.
//   iosClientId:
//     '521885662516-g48v0qgag2316on7qeb9nig7g6g8djfu.apps.googleusercontent.com', // [iOS] if you want to specify the client ID of type iOS (otherwise, it is taken from GoogleService-Info.plist)
// });

export default function AuthOptionsScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const type = location?.pathname;
  const [pageType, setPageType] = useState(type || "/signin");
  const { loading, success, message } = useSelector((state) => state.auth);
  const toast = useToast();
  const userId = useSelector((state) => state.profile.id);
  // const dispatch = useDispatch();

  useEffect(() => {
    setPageType(location.pathname);
  }, [location]);

  // useEffect(() => {
  //   if (pageType === "/signin" && browserSupportsWebAuthn()) {
  //     onPasskeyLogin();
  //   }
  // }, [pageType]);

  const changePage = () => {
    if (pageType === "/signin") {
      navigate("/signup");
    } else {
      navigate("/signin");
    }
  };

  // const onAppleButtonPress = async () => {
  // const appleAuthRequestResponse = await appleAuth.performRequest({
  //   requestedOperation: appleAuth.Operation.LOGIN,
  //   requestedScopes: [appleAuth.Scope.FULL_NAME, appleAuth.Scope.EMAIL],
  // });
  // if (pageType === 'signin') {
  //   dispatch(loginAppleUser({data: appleAuthRequestResponse}));
  // } else {
  //   dispatch(signUpAppleUser({data: appleAuthRequestResponse})).then(res => {
  //     dispatch(
  //       setUser({
  //         email: res?.payload?.email || '',
  //       }),
  //     );
  //     navigation.navigate('SignUp', {
  //       type: 'email',
  //     });
  //   });
  // }
  // };

  const onGoogleLogin = async (credentials) => {
    const user = jwtDecode(credentials.credential);
    const fullname = [user.given_name, user.family_name].join(" ");
    try {
      const response = await dispatch(
        checkUser({ email: user.email })
      ).unwrap();
      if (response.exists) {
        const result = await dispatch(
          loginGoogleUser({ data: { user } })
        ).unwrap();
        if (result?.accessToken) {
          dispatch(setUser(result));
          navigate("/orders");
        } else {
          toast.show({
            description: "Failed to login. Please try again.",
            ...toastErrorConfig,
          });
        }
      } else {
        dispatch(
          setUser({
            email: user?.email || "",
            fullName: fullname || "",
            isEmailVerified: true,
          })
        );
        navigate("/signup/user/1");
      }
    } catch (error) {
      console.error("Error in onGoogleLogin:", error);

      toast.show({
        description: "Failed to login. Please try again later.",
        ...toastErrorConfig,
      });
    }
  };

  const onPasskeyLogin = async () => {
    try {
      await dispatch(passkeyLogin()).unwrap();
      navigate("/orders");
    } catch (error) {
      const errorMessage =
        typeof error === "string" ? error : "An unexpected error occurred";

      toast.show({
        description: errorMessage,
        ...toastErrorConfig,
      });
    }
  };

  // const onFacebookButtonPress = async () => {
  //   dispatch(loginFacebookUser({ data: { type: "facebook" } }));
  // };

  // useEffect(() => {
  //   if (success) {
  //     // navigation.reset({
  //     //   index: 0,
  //     //   routes: [{ name: "OrdersList" }],
  //     // });
  //   }
  // }, [success]);

  // useEffect(() => {
  //   if (!success && message) {
  //     toast.show({
  //       description: message,
  //       placement: "top",
  //     });
  //   }
  // }, [loading, success, message, toast]);

  return (
    <Box style={styles.wrapper}>
      <View
        style={isMobile() ? styles.containerMobile : styles.containerWrapper}
      >
        <View style={styles.container}>
          {pageType === "/signin" ? (
            <Center width={"282"}>
              <Text fontSize="2xl" bold>
                Sign In
              </Text>
              <Text fontSize="md">Welcome back, Sign In to your account</Text>
            </Center>
          ) : (
            <Center width={"282"}>
              <Text fontSize="2xl" bold>
                Sign Up
              </Text>
              <Text fontSize="md">Create account and enjoy all services</Text>
            </Center>
          )}

          <View style={{ marginTop: 10 }}>
            <GoogleLogin
              size={"large"}
              width={300}
              locale={"en_US"}
              text={pageType === "/signin" ? "signin_with" : "signup_with"}
              onSuccess={(credentialResponse) => {
                onGoogleLogin(credentialResponse);
              }}
              onError={() => {
                console.log("Login Failed");
                dispatch(clearErrorMessage());
              }}
            />
          </View>

          {pageType === "/signin" && browserSupportsWebAuthn() && (
            <Button style={styles.button} onPress={onPasskeyLogin}>
              Sign in with Passkey
            </Button>
          )}

          {/*{Platform.OS === "ios" && (*/}
          {/*  <Button*/}
          {/*    leftIcon={*/}
          {/*      <>*/}
          {/*        <Apple />*/}
          {/*      </>*/}
          {/*    }*/}
          {/*    style={styles.button}*/}
          {/*    variant="outline"*/}
          {/*    colorScheme="secondary"*/}
          {/*    onPress={() => onAppleButtonPress()}*/}
          {/*  >*/}
          {/*    {pageType === "signin"*/}
          {/*      ? "Sign In with Apple"*/}
          {/*      : "Sign Up with Apple"}*/}
          {/*  </Button>*/}
          {/*)}*/}
          {/*<AppleButton*/}
          {/*  buttonStyle={AppleButton.Style.WHITE}*/}
          {/*  buttonType={AppleButton.Type.SIGN_IN}*/}
          {/*  style={{*/}
          {/*    width: 160, // You must specify a width*/}
          {/*    height: 45, // You must specify a height*/}
          {/*  }}*/}
          {/*  onPress={() => onAppleButtonPress()}*/}
          {/*/>*/}
          {/*<Button*/}
          {/*  leftIcon={*/}
          {/*    <>*/}
          {/*      <Facebook />*/}
          {/*    </>*/}
          {/*  }*/}
          {/*  style={styles.button}*/}
          {/*  variant="outline"*/}
          {/*  colorScheme="secondary"*/}
          {/*  onPress={onFacebookButtonPress}>*/}
          {/*  {pageType === 'signin'*/}
          {/*    ? 'Sign In with Facebook'*/}
          {/*    : 'Sign Up with Facebook'}*/}
          {/*</Button>*/}

          <HStack style={styles.dividerContainer} justifyContent="center">
            <Box justifyContent="center" style={styles.dividerWrapper}>
              <Box style={styles.divider} />
            </Box>

            <Text style={styles.smallText} fontSize="sm">
              Or continue with
            </Text>
            <Box justifyContent="center" style={styles.dividerWrapper}>
              <Box style={styles.divider} />
            </Box>
          </HStack>
          {pageType === "/signin" ? (
            <>
              <Button
                style={styles.button}
                onPress={() => navigate("/signin/email")}
              >
                Sign In With Email
              </Button>
              <Button
                style={[styles.button, styles.black]}
                variant="outline"
                colorScheme="secondary"
                onPress={() => navigate("/signin/phone")}
              >
                Sign In With Phone
              </Button>
            </>
          ) : (
            <>
              <Button
                style={styles.button}
                onPress={() => navigate("/signup/email")}
              >
                Sign Up With Email
              </Button>
              <Button
                style={[styles.button, styles.black]}
                variant="outline"
                colorScheme="secondary"
                onPress={() => navigate("/signup/phone")}
              >
                Sign Up With Phone
              </Button>
            </>
          )}
        </View>
        {pageType === "/signin" ? (
          <View style={styles.container}>
            <HStack style={styles.changePage}>
              <Text fontSize="sm">Don’t have an account? </Text>
              <Text style={styles.actionText} onPress={changePage}>
                Sign Up
              </Text>
            </HStack>
          </View>
        ) : (
          <View style={styles.container}>
            <HStack style={styles.changePage}>
              <Text fontSize="sm">Already have an account? </Text>
              <Text style={styles.actionText} onPress={changePage}>
                Sign In
              </Text>
            </HStack>
          </View>
        )}
      </View>
    </Box>
  );
}
