const styles = {
  wrapper:{
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 20,
    minHeight: "90vh",
  },
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: 40,
    width: 415,
    height: 236,
    backgroundColor:'#FAFAFA',
    borderRadius:16,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.25,
    shadowRadius: 2.22,
    elevation: 5,

  },
  button: {
    marginBottom: 10,
    borderColor: '#000000',
  },
  text: {
    marginBottom: 20,
    fontSize: 24
  },
};

export default styles;
