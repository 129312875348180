export const headerHeight = 70
export const isMobile = () => {
    return ( ( window.innerWidth <= 600 ) );
}
export const americanStates = [
    'Alabama',
    'Alaska',
    'Arizona',
    'Arkansas',
    'California',
    'Colorado',
    'Colorado',
    'Connecticut',
    'Delaware',
    'Florida',
    'Georgia',
    'Hawaii',
    'Idaho',
    'Illinois',
    'Indiana',
    'Iowa',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Maine',
    'Maryland',
    'Massachusetts',
    'Michigan',
    'Minnesota',
    'Mississippi',
    'Missouri',
    'Montana',
    'Nebraska',
    'Nevada',
    'New Hampshire',
    'New Jersey',
    'New Mexico',
    'New York',
    'North Carolina',
    'North Dakota',
    'Ohio',
    'Oklahoma',
    'Oregon',
    'Pennsylvania',
    'Rhode Island',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'Utah',
    'Vermont',
    'Virginia',
    'Washington',
    'West Virginia',
    'Wisconsin',
    'Wyoming',
];

export const toastErrorConfig = {
    placement: "top",
    backgroundColor: "red.500",
    status: "error",
    maxWidth: "300px"
  };

  export const toastSuccessConfig = {
    placement: "top",
    backgroundColor: "green.500",
    status: "success",
    maxWidth: "300px"
  };

  export const toastWarningConfig = {
    placement: "top",
    backgroundColor: "orange.500",
    status: "success",
    maxWidth: "300px"
  };
  