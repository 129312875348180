import * as React from "react";
import {
  AlertDialog,
  Button,
  Center,
  FlatList,
  FormControl,
  Image,
  Pressable,
  Text,
  TextArea,
  View,
  Box,
  Select,
  Spinner,
  useToast,
  ScrollView,
  Modal,
  Input,
  useBreakpointValue,
  Divider,
} from "native-base";
import styles from "./styles";
import {
  AddImage,
  Calendar,
  EmptyOrdersImage,
  Line,
  Location,
  Plus,
  Truck2,
  Upload,
} from "../../assets/images/svg";
import AddressGrey from "../../assets/images/svg/address-grey";
import { useDispatch, useSelector } from "react-redux";
import {
  getProfile,
  profileAddFCM,
  getOrder,
} from "../../networking/profile/profileApi";
import { formatStringToDate } from "../../utils";
import { useState, useEffect, useRef } from "react";
import {
  completeOrder,
  disputeOrder,
  rateOrder,
  sendOrderToTMS,
} from "../../networking/orders/ordersApi";
import { useNavigate } from "react-router-dom";
import isFeeApplied from "../../utils";
import BidStatus, { textMapper } from "../../components/BidStatus";
import { clearInputValues } from "../../networking/orders/ordersSlice";
import ReactImageUploading from "react-images-uploading";
import instance from "../../networking/axios";
import { API_URL } from "../../config";
import { updateOrder } from "../../networking/profile/profileSlice";
import { toastErrorConfig, toastSuccessConfig } from "../../config/constants";
import {
  cancelOrderWithFee,
  cancelOrderWithoutFee,
  setCarrierProblemStatus,
} from "../../networking/orders/ordersApi";
import { globalLogout } from "../../networking/globalActions";
import PhoneInput from "react-phone-number-input/input";
import { FaThumbsUp, FaThumbsDown } from "react-icons/fa";
import { verifyCredentialToEdit } from "../../networking/auth/authApi";

export default function OrdersListScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const [refreshing, setRefreshing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [completeModalIsOpen, setCompleteModalIsOpen] = useState(false);
  const [disputeModalIsOpen, setDisputeModalIsOpen] = useState(false);
  const [rateModalIsOpen, setRateModalIsOpen] = useState(false);
  const disputeRef = React.useRef(null);
  const [currentOrder, setCurrentOrder] = useState({});
  const { orders, active, isEmailVerified, isPhoneVerified, email, phone } =
    useSelector((state) => state.profile);
  const [filterStatus, setFilterStatus] = useState("all"); // Filter state
  const [verificationModalOpen, setVerificationModalOpen] = useState(false);
  const [dataType, setDataType] = useState(""); // "email" or "phone"
  const [newValue, setNewValue] = useState("");
  const [isPhoneFocused, setIsPhoneFocused] = useState(false);
  const [shownDisclaimerModal, setShownDisclaimerModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedOrders, setSearchedOrders] = useState([]);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  // const isMobile = useBreakpointValue({
  //   base: true, // represents mobile
  //   sm: false, // represents tablet or larger screens
  // });

  useEffect(() => {
    fetchData();
    dispatch(clearInputValues());
  }, [dispatch]);

  // const handleSearchChange = (text) => {
  //   setSearchText(text);
  // };

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const user = await dispatch(getProfile({})).unwrap();
      // logout in case user has deleted: true
      if (user.deleted) {
        dispatch(globalLogout());
        return;
      }
    } catch (error) {
      console.log("Error fetching profile:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRefresh = async () => {
    try {
      setRefreshing(true);
      await dispatch(getProfile({})).unwrap();
    } catch (error) {
      console.log("Error refreshing orders:", error);
    } finally {
      setRefreshing(false);
    }
  };

  const createNewOrder = () => {
    navigate("/create/1");
  };

  const sendOrder = (order) => {
    const today = new Date().toISOString().split("T")[0];
    // Check if the pickup date is before today
    if (order.pickUpDate1 < today) {
      toast.show({
        description: `Pickup date cannot be in the past. Please edit the order and select a valid date`,
        ...toastErrorConfig,
      });
      return;
    }
    const id = order.id;
    dispatch(updateOrder({ id, statusToTMS: "sending" }));
    dispatch(sendOrderToTMS({ data: order }))
      .unwrap()
      .then((sentOrder) => {
        if (sentOrder.success) {
          dispatch(updateOrder({ id, statusToTMS: "sent", status: "auction" }));
          toast.show({
            description: `Order was successfully published`,
            ...toastSuccessConfig,
          });
        } else {
          dispatch(updateOrder({ id: order.id, statusToTMS: "notsent" }));
          toast.show({
            description: sentOrder.message || `Order couldn't be processed`,
            ...toastErrorConfig,
          });
        }
      })
      .catch((err) => {
        dispatch(updateOrder({ id: order.id, statusToTMS: "notsent" }));
        toast.show({
          description: `Something went wrong on our side, your order wasn't published`,
          ...toastErrorConfig,
        });
        console.log("err", err);
      });
  };

  // Parsing the location into city, state, and zip code
  const parseLocation = (address) => {
    const parts = address?.split(", ");
    return {
      city: parts[1] || "",
      state: parts[2]?.split(" ")[0] || "",
      zipCode: parts[2]?.split(" ")[1] || "",
    };
  };

  const OrderItem = ({ order }) => {
    const isInactive = order.statusToTMS === "sending";
    const [step, setStep] = useState(1);
    const [selectReasonModalOpen, setSelectReasonModalOpen] = useState(false);
    const [cancelWithoutFeeAlertOpen, setCancelWithoutFeeAlertOpen] =
      useState(false);
    const [cancelWithFeeAlertOpen, setCancelWithFeeAlert] = useState(false);
    const pickupInfo = parseLocation(order.pickUpLocation);
    const deliveryInfo = parseLocation(order.deliveryLocation);
    const cancelRef = useRef(null);
    const vehicle = order.vehicles[0];

    const handleCancelClick = () => {
      if (isFeeApplied(order.pickUpDate1)) {
        console.log("Fee should be applied");
        setSelectReasonModalOpen(true);
      } else {
        console.log("No fee needed");
        setCancelWithoutFeeAlertOpen(true);
      }
    };

    const handleReasonSelect = (reason) => {
      if (!reason) {
        toast.show({
          description: "Please select the reason",
          ...toastErrorConfig,
        });
        return;
      }
      setSelectReasonModalOpen(false);
      if (reason === "Change of plans") {
        setCancelWithFeeAlert(true);
      } else if (reason === "Carrier didn't arrive") {
        handleCarrierNotArrived();
      }
    };

    const handleCancelWithFee = () => {
      setCancelWithFeeAlert(false);
      dispatch(updateOrder({ id: order.id, isCancelling: true }));
      dispatch(cancelOrderWithFee(order.id))
        .unwrap()
        .then(() => {
          dispatch(updateOrder({ id: order.id, isCancelling: false }));
          setCancelWithFeeAlert(false);
          toast.show({
            description: "Order canceled with a $200 cancellation fee.",
            ...toastSuccessConfig,
          });
          fetchData();
        })
        .catch((error) => {
          dispatch(updateOrder({ id: order.id, isCancelling: false }));
          toast.show({
            description: "We couldn't cancel your order",
            ...toastErrorConfig,
          });
        });
    };

    const handleCancelWithoutFee = () => {
      setCancelWithoutFeeAlertOpen(false);
      dispatch(updateOrder({ id: order.id, isCancelling: true }));
      dispatch(cancelOrderWithoutFee(order.id))
        .unwrap()
        .then(() => {
          dispatch(updateOrder({ id: order.id, isCancelling: false }));
          setCancelWithoutFeeAlertOpen(false);
          toast.show({
            description: "Order canceled with a full refund.",
            ...toastSuccessConfig,
          });
          fetchData();
        })
        .catch((error) => {
          dispatch(updateOrder({ id: order.id, isCancelling: false }));
          toast.show({
            description: "We couldn't cancel your order",
            ...toastErrorConfig,
          });
        });
    };

    const handleCarrierNotArrived = () => {
      dispatch(setCarrierProblemStatus(order.id))
        .unwrap()
        .then(() => {
          dispatch(updateOrder({ id: order.id, status: "carrierProblem" }));
          toast.show({
            description: "Support agent will take care if it soon",
            ...toastSuccessConfig,
          });
        })
        .catch((error) => {
          console.error("Error setting carrier problem status:", error);
          toast.show({
            description: "Failed to update order status. Please try again.",
            ...toastErrorConfig,
          });
        });
    };

    const SelectReasonModal = () => {
      const [reason, setReason] = useState("");
      return (
        <Modal
          isOpen={selectReasonModalOpen}
          onClose={() => setSelectReasonModalOpen(false)}
          useRNModal={true}
        >
          <Modal.Content>
            <Modal.Header>
              {`Are you sure you want to cancel the order with ${vehicle.year} ${vehicle.brand} ${vehicle.model}?`}
            </Modal.Header>
            <Modal.Body>
              <FormControl>
                <Text>Please select the reason for cancellation</Text>
                <FormControl.Label>Reason</FormControl.Label>
                <Select
                  placeholder="Select reason"
                  accessibilityLabel="reason"
                  selectedValue={reason}
                  onValueChange={(reason) => setReason(reason)}
                >
                  <Select.Item
                    label="Change of plans"
                    value="Change of plans"
                  />
                  <Select.Item
                    label="Carrier didn't arrive"
                    value="Carrier didn't arrive"
                  />
                </Select>
              </FormControl>
            </Modal.Body>
            <Modal.Footer>
              <Button
                disabled={!reason}
                onPress={() => {
                  handleReasonSelect(reason);
                }}
              >
                Confirm
              </Button>
              <Button
                style={{ marginTop: 10 }}
                variant="cancel"
                onPress={() => setSelectReasonModalOpen(false)}
              >
                Cancel
              </Button>
            </Modal.Footer>
          </Modal.Content>
        </Modal>
      );
    };

    const CancelWithoutFeeAlert = () => {
      return (
        <AlertDialog
          leastDestructiveRef={cancelRef}
          isOpen={cancelWithoutFeeAlertOpen}
          onClose={() => setCancelWithoutFeeAlertOpen(false)}
          useRNModal={true}
        >
          <AlertDialog.Content>
            <AlertDialog.Header>
              {`Are you sure you want to cancel the order with ${vehicle.year} ${vehicle.brand} ${vehicle.model}?`}
            </AlertDialog.Header>
            <AlertDialog.Body>
              <Text>
                Your order will be saved as draft and carrier won't be able to
                complete it for you. You will receive a full refund to your
                card.
              </Text>
            </AlertDialog.Body>
            <AlertDialog.Footer>
              <Button onPress={handleCancelWithoutFee}>Confirm</Button>
              <Button
                style={{ marginTop: 10 }}
                variant="cancel"
                onPress={() => setCancelWithoutFeeAlertOpen(false)}
              >
                Cancel
              </Button>
            </AlertDialog.Footer>
          </AlertDialog.Content>
        </AlertDialog>
      );
    };

    const CancelWithFeeAlert = () => {
      return (
        <AlertDialog
          leastDestructiveRef={cancelRef}
          isOpen={cancelWithFeeAlertOpen}
          onClose={() => setCancelWithFeeAlert(false)}
          useRNModal={true}
        >
          <AlertDialog.Content>
            <AlertDialog.Header>Final Confirmation</AlertDialog.Header>
            <AlertDialog.Body>
              <Text>
                Your order will be canceled and saved as a draft and you will be
                charged $200 cancellation fee. The rest of your payment will be
                refunded to you card.
              </Text>
            </AlertDialog.Body>
            <AlertDialog.Footer>
              <Button onPress={handleCancelWithFee}>Confirm</Button>
              <Button
                style={{ marginTop: 10 }}
                variant="cancel"
                onPress={() => setCancelWithFeeAlert(false)}
              >
                Cancel
              </Button>
            </AlertDialog.Footer>
          </AlertDialog.Content>
        </AlertDialog>
      );
    };

    return (
      <>
        <View style={{ margin: 10, zIndex: 1 }}>
          <Pressable
            onPress={() =>
              !isInactive &&
              !order.isCancelling &&
              navigate(`/order/${order.id}`)
            }
          >
            <View
              style={[
                styles.orderContainer,
                (isInactive || order.isCancelling) && styles.inactiveContainer,
              ]}
            >
              {/* Header section */}
              <View style={{ flexDirection: "row" }}>
                <Truck2 />
                <View style={{ marginLeft: 20 }}>
                  <Text style={styles.orderName}>
                    {vehicle.year} {vehicle.brand} {vehicle.model}{" "}
                    {order.vehicles.length > 1 &&
                      `(+${order.vehicles.length - 1} more)`}
                  </Text>
                  <View style={styles.auctionStatus}>
                    <Text>Status: </Text>
                    <BidStatus
                      status={
                        order.statusToTMS === "notsent"
                          ? "notSent"
                          : order.status
                      }
                    />
                  </View>
                </View>
              </View>
              {/* Location section */}
              <View style={{ marginTop: 10, flexDirection: "row" }}>
                <View
                  style={{
                    flexDirection: "column",
                    gap: 8,
                    marginRight: 5,
                    fontSize: 14,
                    fontWeight: "400",
                  }}
                >
                  <Text>Pickup:</Text>
                  <Text>Deliery:</Text>
                </View>
                <View>
                  <View style={styles.orderTextContainer}>
                    <Calendar style={styles.icon} />
                    <Text
                      style={styles.orderText}
                      numberOfLines={1}
                      ellipsizeMode="tail"
                    >
                      {formatStringToDate(order.pickUpDate1)}
                    </Text>
                    <Line style={styles.line} />
                    {/* <AddressGrey style={styles.icon} /> */}
                    <Text style={styles.orderText}>
                      {pickupInfo.city}, {pickupInfo.state} {pickupInfo.zipCode}
                    </Text>
                  </View>
                  <View style={styles.orderTextContainer}>
                    <Calendar style={styles.icon} />
                    <Text
                      style={styles.orderText}
                      numberOfLines={1}
                      ellipsizeMode="tail"
                    >
                      {formatStringToDate(order.deliveryDate1)}
                    </Text>
                    <Line style={styles.line} />
                    {/* <AddressGrey style={styles.icon} /> */}
                    <Text style={styles.orderText}>
                      {deliveryInfo.city}, {deliveryInfo.state}{" "}
                      {deliveryInfo.zipCode}
                    </Text>
                  </View>
                </View>
              </View>
              {/* {order.statusToTMS === "draft" && (
                  <Text style={styles.draftText}>
                    Draft: click button below to send
                  </Text>
                )} */}
              <View style={styles.buttonRow}>
                {order.status === "auction" && (
                  <View style={styles.button}>
                    <Button
                      onPress={() => {
                        navigate(`/bids/${order.id}`);
                      }}
                    >
                      Show Bids
                    </Button>
                  </View>
                )}

                {order.status === "completed" && (
                  <View style={styles.button}>
                    <Button
                      isDisabled={!active}
                      onPress={() => {
                        setCurrentOrder(order);
                        setRateModalIsOpen(true);
                      }}
                    >
                      Rate Order
                    </Button>
                  </View>
                )}

                {order.statusToTMS === "sending" && (
                  <View style={styles.sendingContainer}>
                    <Spinner size="small" color="#0000ff" />
                    <Text style={styles.sendingText}>
                      Order is being sent...
                    </Text>
                  </View>
                )}

                {order.isCancelling && (
                  <View style={styles.sendingContainer}>
                    <Spinner size="small" color="#0000ff" />
                    <Text style={styles.sendingText}>
                      Your order is being cancelled...
                    </Text>
                  </View>
                )}

                {order.status === "waitingPickUp" && !order.isCancelling && (
                  <View style={styles.buttonsContainer}>
                    <View style={styles.button}>
                      <Button
                        isDisabled={!active}
                        onPress={() => navigate(`/request-edit/1/${order.id}`)}
                        variant="warning"
                      >
                        Request Edit
                      </Button>
                    </View>
                    <View style={styles.button}>
                      <Button
                        isDisabled={!active}
                        onPress={() => {
                          handleCancelClick();
                        }}
                        variant="fatal"
                      >
                        Cancel Order
                      </Button>
                    </View>
                  </View>
                )}

                {(order.status === "waitingPickUpReviewChanges" ||
                  order.status === "pickedUpReviewChanges") && (
                  <View style={styles.button}>
                    <Button
                      isDisabled={!active}
                      onPress={() => {
                        navigate(`/order/${order.id}`);
                      }}
                    >
                      Review Changes
                    </Button>
                  </View>
                )}

                {order.status === "pickedUp" && (
                  <View style={styles.button}>
                    <Button
                      isDisabled={!active}
                      onPress={() => navigate(`/request-edit/1/${order.id}`)}
                      variant="warning"
                    >
                      Request Edit
                    </Button>
                  </View>
                )}

                {order.status === "new" &&
                  (order.statusToTMS === "notsent" ||
                    order.statusToTMS === "draft") && (
                    <View style={styles.button}>
                      <Button
                        isDisabled={!active}
                        onPress={() => {
                          setCurrentOrder(order);
                          setShownDisclaimerModal(true);
                        }}
                      >
                        Publish Order
                      </Button>
                    </View>
                  )}

                {order.status === "delivered" && (
                  <View style={styles.buttonsContainer}>
                    <View style={styles.button}>
                      <Button
                        isDisabled={!active}
                        onPress={() => {
                          setCurrentOrder(order);
                          setCompleteModalIsOpen(true);
                        }}
                      >
                        Complete Order
                      </Button>
                    </View>

                    {order.status === "delivered" && (
                      <View style={styles.button}>
                        <Button
                          isDisabled={!active}
                          onPress={() => {
                            setCurrentOrder(order);
                            setDisputeModalIsOpen(true);
                          }}
                          variant="fatal"
                        >
                          Start dispute
                        </Button>
                      </View>
                    )}
                  </View>
                )}
              </View>
            </View>
          </Pressable>
        </View>
        <SelectReasonModal />
        <CancelWithoutFeeAlert />
        <CancelWithFeeAlert />
      </>
    );
  };

  const CompleteAlert = () => {
    const onClose = () => {
      setCompleteModalIsOpen(false);
    };
    const complete = () => {
      setCompleteModalIsOpen(false);
      dispatch(completeOrder({ id: currentOrder.id }))
        .unwrap()
        .then(() => {
          dispatch(updateOrder({ id: currentOrder.id, status: "completed" }));
          toast.show({
            description: "Order marked as completed successfully.",
            ...toastSuccessConfig,
          });
          setRateModalIsOpen(true);
        })
        .catch((error) => {
          toast.show({
            description: "Failed to complete the order. Please try again.",
            ...toastErrorConfig,
          });
          console.error("Error completing order:", error);
        });
    };
    return (
      <AlertDialog
        isOpen={completeModalIsOpen}
        onClose={onClose}
        useRNModal={true}
      >
        <AlertDialog.Content>
          <AlertDialog.CloseButton />
          <AlertDialog.Header>Complete Order</AlertDialog.Header>
          <AlertDialog.Body>
            Are you sure that you want to mark the order as a completed? After
            that Dispute will be unavailable and we will send the money to the
            carrier
          </AlertDialog.Body>
          <AlertDialog.Footer>
            <View style={styles.buttonsRow}>
              <Button
                style={styles.cancel}
                variant="subtle"
                colorScheme="coolGray"
                onPress={onClose}
              >
                Cancel
              </Button>
              <Button style={styles.acButton} onPress={complete}>
                Complete
              </Button>
            </View>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>
    );
  };

  const DisputeAlert = () => {
    const [disputeText, setDisputeText] = useState("");
    const [assets, setAssets] = useState([]);
    const [images, setImages] = useState([]);
    const onClose = () => {
      setDisputeModalIsOpen(false);
    };
    const sendDispute = () => {
      setDisputeModalIsOpen(false);
      dispatch(
        disputeOrder({
          data: {
            id: currentOrder.id,
            disputeText,
            disputeImages: images,
          },
        })
      )
        .unwrap()
        .then(() => {
          dispatch(updateOrder({ id: currentOrder.id, status: "dispute" }));
          toast.show({
            description: "Order was successfully disputed.",
            ...toastSuccessConfig,
          });
        })
        .catch((error) => {
          toast.show({
            description: "Failed to dispute the order.",
            ...toastErrorConfig,
          });
          console.error("Error disputing order:", error);
        });
    };

    const uploadResource = async ({ file }) => {
      try {
        const formattedFileName = file.name.replace(/\s+/g, "-"); // Format the file name

        const formData = new FormData();
        formData.append("file", file, formattedFileName);

        const result = await instance.post(
          `${API_URL}/orders/upload`,
          formData
        );

        if (result?.data?.fileName) {
          // Set the uploaded image URL in the state
          setImages((images) => [...images, result.data.fileName]);
        }
      } catch (error) {
        toast.show({
          description: "Failed to upload image. Please try again.",
          ...toastErrorConfig,
        });
        console.error("Error uploading image: ", error);
      }
    };

    const onChange = (imageList, addUpdateIndex) => {
      if (addUpdateIndex) {
        uploadResource(imageList[addUpdateIndex[0]]);
      }
      setAssets(imageList);
    };

    return (
      <AlertDialog
        leastDestructiveRef={disputeRef}
        isOpen={disputeModalIsOpen}
        onClose={onClose}
        useRNModal={true}
      >
        <AlertDialog.Content>
          <AlertDialog.CloseButton />
          <AlertDialog.Header>Dispute Request</AlertDialog.Header>
          <AlertDialog.Body>
            <FormControl.Label>Dispute Request</FormControl.Label>
            <TextArea
              // value={disputeText}
              onChangeText={setDisputeText}
              h={20}
              placeholder="Describe the reason for your dispute"
            />
            <ReactImageUploading
              multiple
              value={assets}
              onChange={onChange}
              maxNumber={10}
              dataURLKey="data_url"
            >
              {({
                imageList,
                onImageUpload,
                onImageRemoveAll,
                onImageUpdate,
                onImageRemove,
                isDragging,
                dragProps,
              }) => (
                // write your building UI
                <div className="upload__image-wrapper">
                  <div
                    style={styles.dragImages}
                    onClick={onImageUpload}
                    {...dragProps}
                  >
                    <Pressable
                      onPress={onImageUpload}
                      style={styles.addPhotoContainer}
                    >
                      <AddImage />
                      <Text style={styles.addPhotoText}>Add Image</Text>
                    </Pressable>
                  </div>
                  <View style={{ flexDirection: "row" }}>
                    {imageList.map((image, index) => (
                      <div
                        key={index}
                        style={{ marginTop: 10, marginRight: 10 }}
                      >
                        <img src={image["data_url"]} alt="" width="100" />
                        <div style={{ textAlign: "center" }}>
                          <button onClick={() => onImageRemove(index)}>
                            Remove
                          </button>
                        </div>
                      </div>
                    ))}
                  </View>
                </div>
              )}
            </ReactImageUploading>
            <View style={styles.buttonsContainer}></View>
            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                gap: 10,
                marginTop: 15,
              }}
            >
              {assets.map((image, index) => (
                <Pressable onPress={() => {}} key={index}>
                  <Image
                    alt={image.uri}
                    style={styles.image}
                    height={66}
                    width={66}
                    src={image.uri}
                  />
                </Pressable>
              ))}
            </View>
          </AlertDialog.Body>
          <AlertDialog.Footer>
            <View style={styles.buttonsRow}>
              <Button
                style={styles.cancel}
                variant="subtle"
                colorScheme="coolGray"
                onPress={onClose}
                ref={disputeRef}
              >
                Cancel
              </Button>
              <Button style={styles.acButton} onPress={sendDispute}>
                Send
              </Button>
            </View>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>
    );
  };

  const RateAlert = () => {
    const onClose = () => {
      setRateModalIsOpen(false);
    };
    const rateRef = React.useRef(null);
    const [selected, setSelected] = useState(null); // Track selected button (up or down)
    const [reviewText, setReviewText] = useState("");

    const handleSubmit = () => {
      if (selected === "down" && !reviewText.trim()) {
        // Ensure a written review is provided for thumbs-down
        toast.show({
          description: "Please provide a reason for your negative feedback",
          ...toastErrorConfig,
        });
        return;
      }
      onClose();

      const reviewData = {
        id: currentOrder.id,
        rating: selected === "down" ? 0 : 1,
        carrierID: currentOrder.carrierID,
        review: reviewText.trim(),
      };

      dispatch(
        rateOrder({
          data: reviewData,
        })
      )
        .unwrap()
        .then(() => {
          dispatch(
            updateOrder({ id: currentOrder.id, status: "completedAndRated" })
          );
          toast.show({
            description: "Thank you for your feedback!",
            ...toastSuccessConfig,
          });
        })
        .catch((error) => {
          toast.show({
            description: "Failed to submit rating. Please try again.",
            ...toastErrorConfig,
          });
        });
    };

    return (
      <AlertDialog isOpen={rateModalIsOpen} onClose={onClose} useRNModal={true}>
        <AlertDialog.Content>
          <AlertDialog.CloseButton />
          <AlertDialog.Header>Feedback</AlertDialog.Header>
          <AlertDialog.Body>
            We'd welcome your feedback! Please rate the carrier and the service.
            <View my={2}>
              <Divider bg="gray.300" thickness="1" />
            </View>
            <View style={styles.buttonsWrapper}>
              {/* Thumbs Up Button */}
              <Button
                style={[
                  styles.thumbButton,
                  selected === "up" && styles.selectedButtonUp,
                ]}
                onPress={() => setSelected("up")}
                _hover={{ bg: "#D1FAE5" }}
                _pressed={{ bg: "#10B981" }}
              >
                <FaThumbsUp
                  size={24}
                  color={selected === "up" ? "white" : "#10B981"}
                />
              </Button>

              {/* Spacer */}
              <View style={styles.spacer}></View>

              {/* Thumbs Down Button */}
              <Button
                style={[
                  styles.thumbButton,
                  selected === "down" && styles.selectedButtonDown,
                ]}
                onPress={() => setSelected("down")}
                _hover={{ bg: "#FEE2E2" }}
                _pressed={{ bg: "#EF4444" }}
              >
                <FaThumbsDown
                  size={24}
                  color={selected === "down" ? "white" : "#EF4444"}
                />
              </Button>
            </View>
            {selected === "down" && (
              <TextArea
                value={reviewText}
                onChange={(e) => setReviewText(e.target.value)}
                placeholder={
                  "Please provide a reason for your negative feedback..."
                }
              />
            )}
          </AlertDialog.Body>
          <AlertDialog.Footer>
            <View style={styles.buttonsRow}>
              <Button
                style={styles.cancel}
                variant="cancel"
                onPress={onClose}
                ref={rateRef}
              >
                Cancel
              </Button>
              <Button
                isDisabled={
                  (selected === "down" &&
                    (reviewText.trim().length < 20 ||
                      reviewText.trim().length > 180)) ||
                  !selected
                }
                style={styles.acButton}
                onPress={handleSubmit}
              >
                Submit
              </Button>
            </View>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>
    );
  };

  const EmptyComponent = () => {
    return (
      <Box style={styles.wrapper}>
        <View style={styles.emptyContainer}>
          <EmptyOrdersImage />
          <Text style={styles.emptyText}>
            Your order history is empty. Begin by creating your first order and
            discovering the convenience of our services.
          </Text>
        </View>
      </Box>
    );
  };

  const filteredOrders = (orders) => {
    let filtered = orders;

    if (orders.length > 0) {
      // if (searchText.trim() !== "") {
      //   filtered = orders.filter((order) => {
      //     const searchContent = [
      //       order.status,
      //       order.pickUpLocation,
      //       order.deliveryLocation,
      //       // order.pickUpDate1,
      //       // order.deliveryDate1,
      //       order.senderContactName || "",
      //       order.senderContactPhone || "",
      //       order.senderContactEmail || "",
      //       order.recipientContactName || "",
      //       order.recipientContactPhone || "",
      //       order.recipientContactEmail || "",
      //       order.carrierName || "",
      //       order.carrierPhone || "",
      //       order.carrierEmail || "",
      //       order.bidAmount ? order.bidAmount.toString() : "",
      //       ...order.vehicles.map((vehicle) =>
      //         [vehicle.vin, vehicle.year, vehicle.brand, vehicle.model].join(
      //           " "
      //         )
      //       ),
      //     ]
      //       .join(" ")
      //       .toLowerCase();

      //     //console.log("Search Content: ", searchContent); // Log the search content
      //     return searchContent.includes(searchText.toLowerCase());
      //   });
      // } else
      {
        // Apply status filter only if there's no search text
        filtered = orders.filter((order) => {
          if (filterStatus === "all") {
            return order.status !== "completedAndRated";
          }
          return order.status === filterStatus;
        });
      }

      // Sort the orders
      return filtered.sort((a, b) => {
        const priorityOrder = {
          carrierProblem: 1,
          dispute: 2,
          auction: 3,
          waitingPickUp: 4,
          pickedUp: 5,
          delivered: 6,
          // Default for other statuses not listed
          default: 7,
        };

        const priorityA = priorityOrder[a.status] || priorityOrder.default;
        const priorityB = priorityOrder[b.status] || priorityOrder.default;

        if (priorityA !== priorityB) {
          return priorityA - priorityB; // Sort by priority
        }

        // If priorities are the same, sort by updatedAt
        return new Date(b.updatedAt) - new Date(a.updatedAt);
      });
    }
  };

  const handleCreateOrderClick = () => {
    if (!isEmailVerified || !isPhoneVerified) {
      setDataType(!isEmailVerified ? "email" : "phone");
      setNewValue(!isEmailVerified ? email : phone);
      setVerificationModalOpen(true);
    } else {
      createNewOrder();
    }
  };

  const handleVerifySubmit = async () => {
    if (dataType === "email") {
      const englishOnlyRegex = /^[\x00-\x7F]+$/; // Matches only ASCII characters (English letters and standard symbols)
      if (!englishOnlyRegex.test(email)) {
        toast.show({
          description: "Email must contain English characters sonly",
          ...toastErrorConfig,
        });
        return;
      }
      if (!newValue.includes("@") || !newValue.includes(".")) {
        toast.show({
          description: "Email is invalid",
          ...toastErrorConfig,
        });
        return;
      }
    }
    if (dataType === "phone") {
      const isValidPhone =
        newValue && newValue.replace(/\D/g, "").length === 11;

      if (!isValidPhone) {
        toast.show({
          description: "Please enter a valid 10-digit phone number.",
          ...toastErrorConfig,
        });
        return;
      }
    }
    if (!newValue) {
      toast.show({
        description: "Please enter a valid value",
        ...toastErrorConfig,
      });
      return;
    }
    const verificationData =
      dataType === "email" ? { email: newValue } : { phone: newValue };
    dispatch(verifyCredentialToEdit({ dataType, verificationData }))
      .unwrap()
      .then(() => {
        setVerificationModalOpen(false);
        navigate(`/profile/confirm/verify/${dataType}/${newValue}`);
      })
      .catch((error) => {
        if (error.status === 409) {
          toast.show({
            description: `This ${dataType} belongs to different account. Please try a different one.`,
            ...toastErrorConfig,
          });
        } else {
          toast.show({
            description:
              "An unexpected error occurred. Please try again later.",
            ...toastErrorConfig,
          });
        }
      });
  };

  const PublishOrderAlert = () => {
    const onClose = () => {
      setShownDisclaimerModal(false);
    };
    return (
      <AlertDialog
        isOpen={shownDisclaimerModal}
        onClose={onClose}
        useRNModal={true}
      >
        <AlertDialog.Content>
          <AlertDialog.CloseButton />
          <AlertDialog.Header>Publish Order</AlertDialog.Header>
          <AlertDialog.Body>
            Attention! If there is no activity and confirmation of your order
            within 24 hours, your listing will be removed from the board.
          </AlertDialog.Body>
          <AlertDialog.Footer>
            <View style={{ flexDirection: "row", flex: 2 }}>
              <Button
                style={styles.cancelButton}
                variant="cancel"
                onPress={onClose}
              >
                Cancel
              </Button>
              <Button
                style={styles.publishButton}
                onPress={() => {
                  setShownDisclaimerModal(false);
                  sendOrder(currentOrder);
                }}
              >
                Publish
              </Button>
            </View>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>
    );
  };

  const handleSelectOrder = (order) => {
    setIsDropdownVisible(false);
    setSearchText("");
    navigate(`/order/${order.id}`);
  };

  useEffect(() => {
    if (searchText) {
      const lowerSearchText = searchText.toLowerCase();
      const filteredOrders = orders.filter((order) => {
        // Create a searchable string for each order
        const searchContent = [
          order.id,
          order.status,
          order.pickUpLocation,
          order.deliveryLocation,
          order.senderContactName,
          order.senderContactPhone,
          order.senderContactEmail,
          order.recipientContactName,
          order.recipientContactPhone,
          order.recipientContactEmail,
          order.carrierName,
          order.carrierPhone,
          order.carrierEmail,
          order.bidAmount?.toString(),
          ...order.vehicles.map(
            (vehicle) =>
              `${vehicle.vin} ${vehicle.year} ${vehicle.brand} ${vehicle.model}`
          ),
        ]
          .join(" ")
          .toLowerCase();

        return searchContent.includes(lowerSearchText);
      });

      setSearchedOrders(filteredOrders);
      setIsDropdownVisible(
        lowerSearchText.length > 0 && filteredOrders.length > 0
      );
    }
  }, [searchText, orders]);

  return (
    <ScrollView style={styles.container}>
      <View style={styles.subHeaderWrapper}>
        <Text style={styles.myOrdersTitle}>My Orders</Text>
        <View style={styles.createNewOrder}>
          <Button isDisabled={!active} onPress={handleCreateOrderClick}>
            Create Order
          </Button>
        </View>
      </View>
      <View mb={2}>
        <Divider bg="gray.300" thickness="1" />
      </View>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: 10,
        }}
      >
        <Select
          selectedValue={filterStatus}
          width="80%"
          accessibilityLabel="Filter by Status"
          placeholder="Filter by Status"
          onValueChange={(itemValue) => setFilterStatus(itemValue)}
        >
          <Select.Item label="Active orders" value="all" />
          <Select.Item label="Drafts" value="new" />
          <Select.Item label="Auction" value="auction" />
          <Select.Item label="Waiting for Pickup" value="waitingPickUp" />
          <Select.Item label="Picked Up" value="pickedUp" />
          <Select.Item label="Delivered" value="delivered" />
          <Select.Item label="Completed" value="completed" />
          <Select.Item label="Disputed" value="dispute" />
          <Select.Item label="Archived" value="completedAndRated" />
        </Select>
        <Input
          placeholder="Type to search orders..."
          value={searchText}
          onChangeText={(searchText) => {
            setSearchText(searchText);
            if (searchText.trim().length > 0) {
              setIsDropdownVisible(true);
            } else {
              setIsDropdownVisible(false);
            }
          }}
          onFocus={() => {
            if (searchText.trim().length > 0) {
              setIsDropdownVisible(true);
            }
          }}
          onBlur={() =>
            setTimeout(() => {
              if (searchText.trim().length > 0) {
                setIsDropdownVisible(false);
              }
            }, 100)
          } // Delays hiding dropdown to allow selection
        />
      </View>
      {isLoading ? (
        <Center flex={1} style={{ marginTop: 50 }}>
          <Spinner size="lg" color="#1DCE80" />
          <Text mt={4}>Loading Orders...</Text>
        </Center>
      ) : (
        <View style={{ marginTop: 5 }}>
          {isDropdownVisible && (
            <View style={styles.dropdownContainer}>
              {searchedOrders.map((order) => (
                <Pressable
                  key={order.id}
                  onPress={() => handleSelectOrder(order)}
                  style={styles.dropdownItem}
                >
                  <View>
                    <Text>Status: {textMapper[order.status]}</Text>
                    <Text>
                      Vehicles: {order.vehicles[0].year}{" "}
                      {order.vehicles[0].brand} {order.vehicles[0].model}{" "}
                      {order.vehicles.length > 1 &&
                        `(+${order.vehicles.length - 1} more)`}
                    </Text>
                    <Text>
                      Pickup: {order.pickUpLocation} -{" "}
                      {formatStringToDate(order.pickUpDate1)}
                    </Text>
                    <Text>
                      Delivery: {order.pickUpLocation} -{" "}
                      {formatStringToDate(order.deliveryDate1)}
                    </Text>
                  </View>
                </Pressable>
              ))}
            </View>
          )}
          <FlatList
            contentContainerStyle={{
              flexDirection: "row",
              flexWrap: "wrap",
              alignItems: "flex-start",
            }}
            data={filteredOrders(orders)}
            renderItem={({ item }) =>
              item.vehicles && item.vehicles.length > 0 ? (
                <OrderItem order={item} />
              ) : (
                <EmptyComponent />
              )
            }
            keyExtractor={(item) => item.id}
            refreshing={refreshing}
            onRefresh={() => {
              handleRefresh;
            }}
            ListEmptyComponent={() => <EmptyComponent />}
            ListFooterComponent={() => <View style={styles.footerBlock} />}
          />
        </View>
      )}
      <Modal
        isOpen={verificationModalOpen}
        useRNModal={true}
        onClose={() => setVerificationModalOpen(false)}
      >
        <Modal.Content maxWidth="400px">
          <Modal.CloseButton />
          <Modal.Header>
            <Text>Verify your {dataType === "email" ? "email" : "phone"}</Text>
          </Modal.Header>
          <Modal.Body>
            <Text style={{ marginBottom: 10 }}>
              Please confirm or edit {dataType} to proceed with order creation.
            </Text>
            {dataType === "email" ? (
              <Input
                placeholder={`Please verify ${dataType}`}
                value={newValue}
                onChangeText={setNewValue}
                keyboardType={
                  dataType === "phone" ? "phone-pad" : "email-address"
                }
              />
            ) : (
              <View
                style={[
                  {
                    flexDirection: "row",
                    alignItems: "center",
                    borderRadius: 12,
                    borderColor: isPhoneFocused ? "#1DCE80" : "#D9D9D9",
                    backgroundColor: isPhoneFocused ? "#E9F8EF" : "FAFAFA",
                    borderWidth: 1,
                    height: 46,
                    width: "100%",
                    paddingHorizontal: 12,
                    outline: isPhoneFocused ? "1px solid #1DCE80" : "none",
                  },
                ]}
              >
                <Text>+1 </Text>
                <PhoneInput
                  value={newValue}
                  onChange={setNewValue}
                  type="phone"
                  maxLength="14"
                  placeholder="(222) 333-4455"
                  defaultCountry="US"
                  style={{
                    outline: "none",
                    flex: 1,
                    borderWidth: 0,
                    backgroundColor: "transparent",
                  }}
                  onFocus={() => setIsPhoneFocused(true)}
                  onBlur={() => setIsPhoneFocused(false)}
                />
              </View>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button onPress={handleVerifySubmit}>Confirm</Button>
            <Button
              style={{ marginTop: 10 }}
              variant="cancel"
              onPress={() => setVerificationModalOpen(false)}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
      <CompleteAlert />
      <DisputeAlert />
      <RateAlert />
      <PublishOrderAlert />
    </ScrollView>
  );
}
