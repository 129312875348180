import * as React from "react";
import {
  Button,
  FormControl,
  Input,
  Select,
  View,
  Text,
  Center,
  useToast,
} from "native-base";
import styles from "./styles";
import {
  Buildings,
  Company,
  Email,
  Geo,
  License,
  Map,
  Parcel,
  Phone,
  User,
  World,
} from "../../assets/images/svg";
import { americanStates } from "../../assets/data/americanStates";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { updateProfile } from "../../networking/profile/profileApi";
import { useNavigate } from "react-router-dom";
import Autocomplete from "react-google-autocomplete";
import { GOOGLE_API_KEY } from "../../config";
import PhoneInput from "react-phone-number-input/input";
import { toastErrorConfig, toastSuccessConfig } from "../../config/constants";

export default function EditProfileScreen() {
  const toast = useToast();
  const isCompany = false;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profile = useSelector((state) => state.profile);
  const [phone, setPhone] = useState(profile.phone || "");
  const [email, setEmail] = useState(profile.email || "");
  const [city, setCity] = useState(profile.address.city || "");
  const [state, setState] = useState(profile.address.state || "");
  const [zip, setZip] = useState(profile.address.zip || "");
  const [county, setCounty] = useState(profile.address.county || "");
  const [streetAddressLine1, setStreetAddressLine1] = useState(
    profile.address.streetAddressLine1 || ""
  );

  const [streetAddressLine2, setStreetAddressLine2] = useState(
    profile.address.streetAddressLine2 || ""
  );
  const [isPhoneFocused, setIsPhoneFocused] = useState(false);
  const [isAddressFocused, setIsAddressFocused] = useState(false);
  const [addressLine, setAddressLine] = useState(streetAddressLine1);

  const editProfile = async () => {
    if (!email.includes("@") || !email.includes(".")) {
      toast.show({
        description: "Email is invalid",
        ...toastErrorConfig,
      });
      return;
    }

    const isValidPhone = phone && phone.replace(/\D/g, "").length === 11;

    if (!isValidPhone) {
      toast.show({
        description: "Please enter a valid 10-digit phone number.",
        ...toastErrorConfig,
      });
      return;
    }

    if (!streetAddressLine1 || !city || !state || !zip) {
      toast.show({
        description: "Please complete all required address fields.",
        ...toastErrorConfig,
      });
      return;
    }

    try {
      dispatch(
        updateProfile({
          state,
          city,
          zip,
          county: county || "",
          streetAddressLine1: addressLine,
          streetAddressLine2,
        })
      );
      toast.show({
        description: "Profile was successfully updated",
        ...toastSuccessConfig,
      });
      navigate(-1);
    } catch (error) {
      toast.show({
        description: "Failed to update the profile",
        ...toastErrorConfig,
      });
    }
  };

  return (
    <Center style={styles.center}>
      <View style={styles.container}>
        <Text style={styles.myProfileTitle}>Edit Address</Text>
        <FormControl>
          {/* <FormControl.Label>Full Name</FormControl.Label>
          <Input
            type="text"
            isDisabled={true}
            value={profile?.fullName}
            InputLeftElement={
              <View style={styles.leftIcon}>
                <User stroke={"#737373"} />
              </View>
            }
          />
          <View style={styles.divider} /> */}
          {/* <FormControl.Label>Email</FormControl.Label>
          <Input
            textContentType="emailAddress"
            keyboardType="email-address"
            autoCapitalize="none"
            type="email"
            autoCorrect={false}
            autoCompleteType="email"
            value={email}
            onChangeText={setEmail}
            InputLeftElement={
              <View style={styles.leftIcon}>
                <Email />
              </View>
            }
            placeholder="example@example.com"
          />
          <View style={styles.divider} /> */}
          {/* <FormControl.Label>Phone Number</FormControl.Label>
          <View
            style={[
              {
                flexDirection: "row",
                alignItems: "center",
                borderRadius: 12,
                borderColor: isPhoneFocused ? "#1DCE80" : "#D9D9D9",
                backgroundColor: isPhoneFocused ? "#E9F8EF" : "#FFFFFF",
                borderWidth: 1,
                height: 46,
                width: "100%",
                paddingHorizontal: 12,
                outline: isPhoneFocused ? "1px solid #1DCE80" : "none",
              },
            ]}
          >
            <View style={{ marginRight: 10 }}>
              <Phone />
            </View>
            <PhoneInput
              value={phone}
              onChange={setPhone}
              type="phone"
              maxLength="14"
              placeholder="(222) 333-4455"
              defaultCountry="US"
              style={{
                paddingLeft: 0,
                outline: "none",
                flex: 1,
                borderWidth: 0,
                backgroundColor: isPhoneFocused ? "#E9F8EF" : "#FFFFFF",
              }}
              onFocus={() => setIsPhoneFocused(true)}
              onBlur={() => setIsPhoneFocused(false)}
            />
          </View> */}
          <View style={styles.divider} />
          <FormControl.Label>Street Address Line 1</FormControl.Label>
          <View
            style={[
              {
                flexDirection: "row",
                alignItems: "center",
                borderRadius: 12,
                borderColor: isAddressFocused ? "#1DCE80" : "#D9D9D9",
                backgroundColor: isAddressFocused ? "#E9F8EF" : "#FFFFFF",
                borderWidth: 1,
                height: 46,
                width: "100%",
                paddingHorizontal: 12,
                outline: isAddressFocused ? "1px solid #1DCE80" : "none",
              },
            ]}
          >
            <View style={{ marginRight: 10 }}>
              <Company />
            </View>
            <Autocomplete
              apiKey={GOOGLE_API_KEY}
              value={addressLine || ""}
              placeholder="Street Address"
              style={{
                paddingLeft: 0,
                outline: "none",
                flex: 1,
                borderWidth: 0,
                backgroundColor: isAddressFocused ? "#E9F8EF" : "#FFFFFF",
              }}
              onChange={(e) => setAddressLine(e.target.value)}
              onPlaceSelected={(details) => {
                const streetNumber = details.address_components.find(
                  (component) => component.types.includes("street_number")
                )?.long_name;
                const route = details.address_components.find((component) =>
                  component.types.includes("route")
                )?.long_name;
                const displayCity = details.address_components.filter(
                  (f) =>
                    JSON.stringify(f.types) ===
                    JSON.stringify(["locality", "political"])
                )[0]?.long_name;
                const displayState = details.address_components.filter(
                  (f) =>
                    JSON.stringify(f.types) ===
                    JSON.stringify(["administrative_area_level_1", "political"])
                )[0]?.long_name;

                const zip = details.address_components.filter(
                  (f) =>
                    JSON.stringify(f.types) === JSON.stringify(["postal_code"])
                )[0]?.long_name;
                const formattedStreetAddress = `${streetNumber || ""} ${
                  route || ""
                }`.trim();
                setStreetAddressLine1(formattedStreetAddress);
                setAddressLine(formattedStreetAddress);
                setCity(displayCity);
                setState(displayState);
                setZip(zip);
              }}
              options={{
                types: ["address"],
                componentRestrictions: { country: "us" },
              }}
              onFocus={() => setIsAddressFocused(true)}
              onBlur={() => setIsAddressFocused(false)}
            />
          </View>
          <View style={styles.divider} />
          <FormControl.Label>
            Street Address Line 2 (Apt, suit, other)
          </FormControl.Label>
          <Input
            type="text"
            value={streetAddressLine2}
            onChangeText={setStreetAddressLine2}
            InputLeftElement={
              <View style={styles.leftIcon}>
                <Buildings />
              </View>
            }
            placeholder="Street Address"
          />
          <View style={styles.divider} />
          <FormControl.Label>City</FormControl.Label>
          <Input
            type="text"
            value={city}
            onChangeText={setCity}
            InputLeftElement={
              <View style={styles.leftIcon}>
                <Geo />
              </View>
            }
            placeholder="City"
          />
          <View style={styles.divider} />
          <FormControl.Label>State</FormControl.Label>
          <Select
            InputLeftElement={
              <View style={styles.leftIcon}>
                <Map />
              </View>
            }
            onValueChange={setState}
            selectedValue={state}
            minWidth="200"
            accessibilityLabel="State"
            placeholder="Registration State"
            mt="1"
          >
            {americanStates.map((name) => (
              <Select.Item key={name} label={name} value={name} />
            ))}
          </Select>
          <View style={styles.divider} />
          <FormControl.Label>ZIP Code</FormControl.Label>
          <Input
            type="text"
            value={zip}
            onChangeText={setZip}
            InputLeftElement={
              <View style={styles.leftIcon}>
                <Parcel />
              </View>
            }
            placeholder="ZIP Code"
          />
          <View style={styles.divider} />
          <FormControl.Label>County (Optional)</FormControl.Label>
          <Input
            type="text"
            value={county}
            onChangeText={setCounty}
            InputLeftElement={
              <View style={styles.leftIcon}>
                <World />
              </View>
            }
            placeholder="County"
          />
          <View style={styles.divider} />
        </FormControl>
        <View style={styles.divider} />
        <Button onPress={editProfile} style={styles.button}>
          Save
        </Button>
        <View style={styles.bottomSpace} />
        {isCompany && (
          <FormControl>
            <FormControl.Label>Company Name</FormControl.Label>
            <Input
              type="text"
              isDisabled={true}
              value={"DispatchFull"}
              InputLeftElement={
                <View style={styles.leftIcon}>
                  <Company stroke={"#737373"} />
                </View>
              }
            />
            <View style={styles.divider} />
            <FormControl.Label>License Number</FormControl.Label>
            <Input
              isDisabled={true}
              type="text"
              value={"122223231221"}
              InputLeftElement={
                <View style={styles.leftIcon}>
                  <License stroke={"#737373"} />
                </View>
              }
              placeholder="License Number"
            />
            <View style={styles.divider} />
            <FormControl.Label>Email</FormControl.Label>
            <Input
              textContentType="emailAddress"
              keyboardType="email-address"
              autoCapitalize="none"
              type="email"
              autoCorrect={false}
              autoCompleteType="email"
              value={""}
              InputLeftElement={
                <View style={styles.leftIcon}>
                  <Email />
                </View>
              }
              placeholder="Email Address"
            />
            <View style={styles.divider} />
            <FormControl.Label>Phone Number</FormControl.Label>
            <Input
              value={"00000000000"}
              type="tel"
              InputLeftElement={
                <View style={styles.leftIcon}>
                  <Phone />
                </View>
              }
              placeholder="Enter your Phone Number"
            />
            <View style={styles.divider} />
          </FormControl>
        )}
      </View>
    </Center>
  );
}
