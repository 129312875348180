import * as React from "react";
import {
  Text,
  View,
  AlertDialog,
  Button,
  Input,
  useToast,
  Center,
  Modal,
  Pressable,
} from "native-base";
import styles from "./styles";
import { Building, Logout, Person, Trash } from "../../assets/images/svg";
import { useDispatch, useSelector } from "react-redux";
import { globalLogout } from "../../networking/globalActions";
import { useState } from "react";
import instance from "../../networking/axios";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../config";
import { toastErrorConfig, toastSuccessConfig } from "../../config/constants";
import { logOutAction } from "../../networking/auth/authSlice";
import { checkUser } from "../../networking/auth/authApi";
import PhoneInput from "react-phone-number-input/input";
import { verifyCredentialToEdit } from "../../networking/auth/authApi";

export default function MyProfileScreen() {
  const navigate = useNavigate();
  const editProfile = () => {
    navigate("/profile/edit");
  };
  const isCompany = false;
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const { fullName, email, phone, address, isEmailVerified, isPhoneVerified, active } =
    useSelector((state) => state.profile);
  const cancelRef = React.useRef(null);
  const toast = useToast();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editType, setEditType] = useState(""); // "phone" or "email"
  const [newValue, setNewValue] = useState("");
  const [verificationModalOpen, setVerificationModalOpen] = useState(false);
  const [dataType, setDataType] = useState(""); // "email" or "phone"
  const [isPhoneFocused, setIsPhoneFocused] = useState(false);

  const fullAddress =
    address?.streetAddressLine1 &&
    address?.city &&
    address?.state &&
    address?.zip
      ? `${address.streetAddressLine1} ${address?.streetAddressLine2} ${address.city}, ${address.state} ${address.zip}`
      : "";

  const formatPhoneNumber = (phone) => {
    if (!phone) return "";
    const cleaned = phone.replace(/\D/g, "");

    const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[2]}) ${match[3]}-${match[4]}`;
    }
    return phone;
  };

  const onClose = () => {
    if (isDeleted) {
      onDeleted();
    } else {
      setIsOpen(false);
    }
  };
  const onDelete = async () => {
    await instance
      .delete(`${API_URL}/auth/profile`)
      .then((res) => {
        setIsDeleted(true);
        toast.show({
          description: "Yourt account was successfully deleted",
          ...toastSuccessConfig,
        });
      })
      .catch((err) => {
        console.log("err", err);
        toast.show({
          description: "Sorry, we couldn't delete your order",
          ...toastErrorConfig,
        });
      });
  };

  const logOut = () => {
    dispatch(globalLogout());
    navigate("/");
  };

  const editValueModal = (type) => {
    setEditType(type);
    setNewValue(type === "email" ? email : phone);
    setIsEditModalOpen(true);
  };

  const handleEditSubmit = async () => {
    if (
      (editType === "email" && newValue === email) ||
      (editType === "phone" && newValue === phone)
    ) {
      toast.show({
        description: `Please enter new ${editType}`,
        ...toastErrorConfig,
      });
      return;
    }

    const verificationData = {};
    let dataType = "";
    if (editType === "email") {
      const englishOnlyRegex = /^[\x00-\x7F]+$/; // Matches only ASCII characters (English letters and standard symbols)
      if (!englishOnlyRegex.test(newValue)) {
        toast.show({
          description: "Email must contain English characters only",
          ...toastErrorConfig,
        });
        return;
      }
      if (!newValue.includes("@") || !newValue.includes(".")) {
        toast.show({
          description: "Email is invalid",
          ...toastErrorConfig,
        });
        return;
      }
      verificationData.email = newValue;
      dataType = "email";
    }
    if (editType === "phone") {
      const isValidPhone =
        newValue && newValue.replace(/\D/g, "").length === 11;
      if (!isValidPhone) {
        toast.show({
          description: "Please enter a valid 10-digit phone number.",
          ...toastErrorConfig,
        });
        return;
      }
      verificationData.phone = newValue;
      dataType = "phone";
    }
    if (!newValue) {
      toast.show({
        description: "Please enter a valid value",
        ...toastErrorConfig,
      });
      return;
    }

    console.log("verificationData", verificationData);

    dispatch(verifyCredentialToEdit({ dataType, verificationData }))
      .unwrap()
      .then(() => {
        setIsEditModalOpen(false);
        navigate(`/profile/confirm/update/${dataType}/${newValue}`);
      })
      .catch((error) => {
        if (error.status === 409) {
          toast.show({
            description: `This ${dataType} belongs to different account. Please try a different one.`,
            ...toastErrorConfig,
          });
        } else {
          toast.show({
            description:
              "An unexpected error occurred. Please try again later.",
            ...toastErrorConfig,
          });
        }
      });
  };

  const handleVerifySubmit = () => {
    if (dataType === "email") {
      if (!newValue.includes("@") || !newValue.includes(".")) {
        toast.show({
          description: "Email is invalid",
          ...toastErrorConfig,
        });
        return;
      }
    }
    if (dataType === "phone") {
      const isValidPhone =
        newValue && newValue.replace(/\D/g, "").length === 11;

      if (!isValidPhone) {
        toast.show({
          description: "Please enter a valid 10-digit phone number.",
          ...toastErrorConfig,
        });
        return;
      }
    }
    if (!newValue) {
      toast.show({
        description: "Please enter a valid value",
        ...toastErrorConfig,
      });
      return;
    }
    const verificationData =
      dataType === "email" ? { email: newValue } : { phone: newValue };

    dispatch(verifyCredentialToEdit({ dataType, verificationData }))
      .unwrap()
      .then(() => {
        setVerificationModalOpen(false);
        navigate(`/profile/confirm/verify/${dataType}/${newValue}`);
      })
      .catch((error) => {
        if (error.status === 409) {
          toast.show({
            description: `This ${dataType} belongs to different account. Please try a different one.`,
            ...toastErrorConfig,
          });
        } else {
          toast.show({
            description:
              "An unexpected error occurred. Please try again later.",
            ...toastErrorConfig,
          });
        }
      });
  };

  const handleVerifyClick = () => {
    if (!isEmailVerified || !isPhoneVerified) {
      setDataType(!isEmailVerified ? "email" : "phone");
      setNewValue(!isEmailVerified ? email : phone);
      setVerificationModalOpen(true);
    }
  };

  const onDeleted = () => {
    dispatch(logOutAction());
    navigate("/");
  };
  const [isDeleted, setIsDeleted] = React.useState(false);

  const handleDelete = async () => {
    try {
      const response = await dispatch(checkUser({ email })).unwrap();
      if (response.deleted) {
        dispatch(globalLogout());
        toast.show({
          description: "Your profile is already deleted",
          ...toastErrorConfig,
        });
        return;
      }
      if (response.hasActiveOrders) {
        toast.show({
          description:
            "You can't delete profile, because you have orders that may need your attention",
          ...toastErrorConfig,
        });
        return;
      }
      if (response.hasPendingPayments) {
        toast.show({
          description:
            "You can't delete profile, because you have pending payments",
          ...toastErrorConfig,
        });
        return;
      }
      setIsOpen(!isOpen);
    } catch (error) {
      toast.show({
        description: "Couldn't check user",
        ...toastErrorConfig,
      });
    }
  };

  return (
    <>
      <Center style={styles.center}>
        <View style={styles.container}>
          <View style={styles.subHeaderWrapper}>
            <Text style={styles.myProfileTitle}>My Profile</Text>
            {/* <Text style={styles.actionText} onPress={editProfile}>
              Edit
            </Text> */}
          </View>
          {!isCompany && (
            <View style={styles.infoContainer}>
              <View style={[styles.row, { marginBottom: 12 }]}>
                <Person style={styles.icon} />
                <Text style={styles.rowHeader}>Profile info</Text>
              </View>
              <Text style={styles.rowHeader}>Full Name</Text>
              <Text style={styles.rowText}>{fullName}</Text>
              <View style={styles.divider} />
              <Text style={styles.rowHeader}>Email</Text>
              <View style={styles.editFields}>
                <Text style={styles.rowText}>{email}</Text>
                {!isEmailVerified ? (
                  <Pressable isDisabled={!active} onPress={handleVerifyClick}>
                    <Text style={styles.actionText}>Verify</Text>
                  </Pressable>
                ) : (
                  <Pressable isDisabled={!active} onPress={() => editValueModal("email")}>
                    <Text style={styles.actionText}>Edit</Text>
                  </Pressable>
                )}
              </View>
              <View style={styles.divider} />
              <Text style={styles.rowHeader}>Phone</Text>
              <View style={styles.editFields}>
                <Text style={styles.rowText}>
                  +1 {formatPhoneNumber(phone)}
                </Text>
                {!isPhoneVerified ? (
                  <Pressable isDisabled={!active} onPress={handleVerifyClick}>
                    <Text style={styles.actionText}>Verify</Text>
                  </Pressable>
                ) : (
                  <Pressable isDisabled={!active} onPress={() => editValueModal("phone")}>
                    <Text style={styles.actionText}>Edit</Text>
                  </Pressable>
                )}
              </View>
              <View style={styles.divider} />
              <Text style={styles.rowHeader}>Address</Text>
              <View style={styles.editFields}>
                <Text style={styles.rowText}>{fullAddress}</Text>
                <Pressable isDisabled={!active} onPress={editProfile}>
                  <Text style={styles.actionText}>Edit</Text>
                </Pressable>
              </View>
            </View>
          )}
          {isCompany && (
            <View style={styles.infoContainer}>
              <View mb={"10px"} style={styles.row}>
                <Building />
                <Text style={styles.header}>Company information</Text>
              </View>

              <Text style={styles.rowHeader}>Company Name</Text>
              <Text style={styles.rowText}>DispatchFull</Text>
              <View style={styles.divider} />

              <Text style={styles.rowHeader}>License Number</Text>
              <Text style={styles.rowText}>1222 2323 1221</Text>
              <View style={styles.divider} />

              <Text style={styles.rowHeader}>Email</Text>
              <Text style={styles.rowText} />
              <View style={styles.divider} />

              <Text style={styles.rowHeader}>Phone</Text>
              <Text style={styles.rowText}>+1 (000)00000000</Text>
              <View style={styles.divider} />

              <Text style={styles.rowHeader}>Address</Text>
              <Text style={styles.rowText}>
                One Apple Park Way Cupertino, CA 95014
              </Text>
              <View style={styles.divider} />
              <View mt={"16px"} mb={"10px"} style={styles.row}>
                <Person />
                <Text style={styles.header}>Contact person</Text>
              </View>

              <Text style={styles.rowHeader}>Full Name</Text>
              <Text style={styles.rowText} />
              <View style={styles.divider} />
              <Text style={styles.rowHeader}>Position</Text>
              <Text style={styles.rowText}>CFO</Text>
              <View style={styles.divider} />
              <Text style={styles.rowHeader}>Email</Text>
              <Text style={styles.rowText} />
              <View style={styles.divider} />
              <Text style={styles.rowHeader}>Phone</Text>
              <Text style={styles.rowText}>+1 (000)00000000</Text>
            </View>
          )}
          <Pressable onPress={handleDelete}>
            <View style={styles.infoContainer}>
              <View style={styles.row}>
                <Trash style={styles.icon} />
                <Text style={styles.rowHeader}>Delete account</Text>
              </View>
            </View>
          </Pressable>
          <Pressable onPress={logOut}>
            <View style={styles.infoContainer}>
              <View style={styles.row}>
                <Logout style={styles.icon} />
                <Text style={styles.rowHeader}>Log out</Text>
              </View>
            </View>
          </Pressable>
          <View style={styles.emptySpace} />
        </View>
      </Center>
      <AlertDialog
        leastDestructiveRef={cancelRef}
        isOpen={isOpen}
        onClose={onClose}
        useRNModal={true}
      >
        <AlertDialog.Content>
          <AlertDialog.CloseButton />
          <AlertDialog.Header>Delete Account</AlertDialog.Header>
          <AlertDialog.Body>
            {isDeleted
              ? "Your account was successfully deleted"
              : "Are you sure you want to delete your account? This action is " +
                "irreversible and will result in the permanent deletion of all your " +
                "account information, including your posts, comments, and personal " +
                "settings. Once deleted, your profile cannot be recovered."}
          </AlertDialog.Body>
          <AlertDialog.Footer>
            {isDeleted ? (
              <Button style={styles.deleteButton} onPress={onDeleted}>
                Ok
              </Button>
            ) : (
              <View style={styles.buttonsRow}>
                <Button
                  style={styles.cancel}
                  variant="cancel"
                  onPress={onClose}
                  ref={cancelRef}
                >
                  Cancel
                </Button>
                <Button
                  style={styles.deleteButton}
                  variant="fatal"
                  onPress={onDelete}
                >
                  Delete
                </Button>
              </View>
            )}
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>
      <Modal
        isOpen={isEditModalOpen}
        useRNModal={true}
        onClose={() => setIsEditModalOpen(false)}
      >
        <Modal.Content maxWidth="400px">
          <Modal.CloseButton />
          <Modal.Header>
            <Text>Edit {editType === "email" ? "email" : "phone"}</Text>
          </Modal.Header>
          <Modal.Body>
            <Text style={{ marginBottom: 10 }}>Please enter new value</Text>
            {editType === "email" ? (
              <Input
                placeholder={`Enter new ${editType}`}
                value={newValue}
                onChangeText={setNewValue}
                keyboardType={
                  editType === "phone" ? "phone-pad" : "email-address"
                }
              />
            ) : (
              <View
                style={[
                  {
                    flexDirection: "row",
                    alignItems: "center",
                    borderRadius: 12,
                    borderColor: isPhoneFocused ? "#1DCE80" : "#D9D9D9",
                    backgroundColor: isPhoneFocused ? "#E9F8EF" : "FAFAFA",
                    borderWidth: 1,
                    height: 46,
                    width: "100%",
                    paddingHorizontal: 12,
                    outline: isPhoneFocused ? "1px solid #1DCE80" : "none",
                  },
                ]}
              >
                <Text>+1 </Text>
                <PhoneInput
                  value={newValue}
                  onChange={setNewValue}
                  type="phone"
                  maxLength="14"
                  placeholder="(222) 333-4455"
                  defaultCountry="US"
                  style={{
                    outline: "none",
                    flex: 1,
                    borderWidth: 0,
                    backgroundColor: "transparent",
                  }}
                  onFocus={() => setIsPhoneFocused(true)}
                  onBlur={() => setIsPhoneFocused(false)}
                />
              </View>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button onPress={handleEditSubmit}>Confirm</Button>
            <Button
              style={{ marginTop: 10 }}
              variant="outline"
              onPress={() => setIsEditModalOpen(false)}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
      <Modal
        isOpen={verificationModalOpen}
        useRNModal={true}
        onClose={() => setVerificationModalOpen(false)}
      >
        <Modal.Content maxWidth="400px">
          <Modal.CloseButton />
          <Modal.Header>
            <Text>Verify your {dataType === "email" ? "email" : "phone"}</Text>
          </Modal.Header>
          <Modal.Body>
            <Text style={{ marginBottom: 10 }}>
              Please confirm or edit {dataType} to proceed.
            </Text>
            {editType === "email" ? (
              <Input
                placeholder={`Please verify ${dataType}`}
                value={newValue}
                onChangeText={setNewValue}
                keyboardType={
                  dataType === "phone" ? "phone-pad" : "email-address"
                }
              />
            ) : (
              <View
                style={[
                  {
                    flexDirection: "row",
                    alignItems: "center",
                    borderRadius: 12,
                    borderColor: isPhoneFocused ? "#1DCE80" : "#D9D9D9",
                    backgroundColor: isPhoneFocused ? "#E9F8EF" : "FAFAFA",
                    borderWidth: 1,
                    height: 46,
                    width: "100%",
                    paddingHorizontal: 12,
                    outline: isPhoneFocused ? "1px solid #1DCE80" : "none",
                  },
                ]}
              >
                <Text>+1 </Text>
                <PhoneInput
                  value={newValue}
                  onChange={setNewValue}
                  type="phone"
                  maxLength="14"
                  placeholder="(222) 333-4455"
                  defaultCountry="US"
                  style={{
                    outline: "none",
                    flex: 1,
                    borderWidth: 0,
                    backgroundColor: "transparent",
                  }}
                  onFocus={() => setIsPhoneFocused(true)}
                  onBlur={() => setIsPhoneFocused(false)}
                />
              </View>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button onPress={handleVerifySubmit}>Confirm</Button>
            <Button
              style={{ marginTop: 10 }}
              variant="cancel"
              onPress={() => setVerificationModalOpen(false)}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </>
  );
}
