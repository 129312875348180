import * as React from "react";
import {
  Button,
  Center,
  Checkbox,
  FormControl,
  Input,
  Text,
  View,
  useToast,
  AlertDialog,
  Pressable,
} from "native-base";
import styles from "./styles";
import { Email, Phone, User } from "../../../assets/images/svg";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { carsObj } from "../../../assets/data/carsList";
import { setInputValue } from "../../../networking/orders/ordersSlice";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import BottomNavigator from "../../../components/BottomNavigator";
import PhoneInput from "react-phone-number-input/input";
import {
  toastErrorConfig,
  toastSuccessConfig,
} from "../../../config/constants";
import Stepper from "../../../components/stepper";
import Autocomplete from "react-google-autocomplete";
import { Geo } from "../../../assets/images/svg";
import { createPlace } from "../../../networking/orders/ordersApi";

export default function NOFFourthStepScreen() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  // const [isSenderPhoneFocused, setIsSenderPhoneFocused] = useState(false);
  const [isRecipientPhoneFocused, setIsRecipientPhoneFocused] = useState(false);
  const toast = useToast();
  const [deliveryLocationCoordinates, setDeliveryLocationCoordinates] =
    useState({});
  const [shownCancelPINModal, setShownCancelPINModal] = useState(false);
  const [modalLocation, setModalLocation] = useState(""); // Track whether it's for pickup or delivery
  const orderState = useSelector((state) => state.order);
  const { fullName, email, phone, active } = useSelector(
    (state) => state.profile
  );
  const { deliveryLocation, deliveryPINRequired, places } = orderState;
  const [localInputs, setLocalInputs] = useState({
    deliveryLocation: orderState.deliveryLocation || "",
    recipientContactName: orderState.recipientContactName || "",
    recipientContactPhone: orderState.recipientContactPhone || "",
    recipientContactEmail: orderState.recipientContactEmail || "",
    checkboxRecipient: orderState.checkboxRecipient || false,
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [placeName, setPlaceName] = useState("");
  const [localInputValue, setLocalInputValue] = useState("");
  const [filteredPlaces, setFilteredPlaces] = useState([]);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isPlaceSaved, setIsPlaceSaved] = useState(false);

  const handleOpenSavePlaceModal = () => {
    if (!localInputs.deliveryLocation) {
      toast.show({
        description: "Please fill in  delivery location",
        ...toastErrorConfig,
      });
      return;
    }

    if (
      !localInputs.recipientContactName ||
      !localInputs.recipientContactEmail ||
      !localInputs.recipientContactPhone
    ) {
      toast.show({
        description: "Please fill in recipient information to proceed",
        ...toastErrorConfig,
      });
      return;
    }

    if (
      !localInputs.recipientContactEmail.includes("@") ||
      !localInputs.recipientContactEmail.includes(".")
    ) {
      toast.show({
        description: "Recipient email is invalid",
        ...toastErrorConfig,
      });
      return;
    }

    const isValidRecipientPhone =
      localInputs.recipientContactPhone &&
      localInputs.recipientContactPhone.replace(/\D/g, "").length === 11;

    if (!isValidRecipientPhone) {
      toast.show({
        description: "Please enter a valid 10-digit recipient's phone number",
        ...toastErrorConfig,
      });
      return;
    }
    setIsModalVisible(true);
  };

  const handleCloseSavePlaceModal = () => {
    setIsModalVisible(false);
    setPlaceName(""); // Reset place name on close
  };

  const handleConfirmSavePlaceModal = async () => {
    const placeData = {
      placeName,
      fullName: localInputs.recipientContactName,
      phone: localInputs.recipientContactPhone,
      email: localInputs.recipientContactEmail,
      address: localInputs.deliveryLocation,
    };
    try {
      await dispatch(createPlace(placeData)).unwrap();
      toast.show({
        description: "Place saved successfully!",
        ...toastSuccessConfig,
      });
      setIsPlaceSaved(true); 
      handleCloseSavePlaceModal();
    } catch (error) {
      console.error("Failed to save place:", error);
      toast.show({
        description: "Failed to save place. Please try again.",
        ...toastErrorConfig,
      });
    }
  };

  const handleChangePlaceName = (value) => {
    setPlaceName(value);
  };

  const handleChange = (key, value) => {
    setLocalInputs((prev) => ({ ...prev, [key]: value }));
  };

  const handleCloseModal = () => {
    setShownCancelPINModal(false);
  };

  const handleCancelPIN = () => {
    // Uncheck the box when user confirms PIN cancellation
    if (modalLocation === "pickup") {
      dispatch(setInputValue({ key: "pickUpPINRequired", value: false }));
      setWasPickupChecked(false);
    } else if (modalLocation === "delivery") {
      dispatch(setInputValue({ key: "deliveryPINRequired", value: false }));
      setWasDeliveryChecked(false);
    }
    setShownCancelPINModal(false);
  };

  const nextStep = () => {
    if (!localInputs.deliveryLocation) {
      toast.show({
        description: "Please fill in  delivery location",
        ...toastErrorConfig,
      });
      return;
    }

    if (
      !localInputs.recipientContactName ||
      !localInputs.recipientContactEmail ||
      !localInputs.recipientContactPhone
    ) {
      toast.show({
        description: "Please fill in recipient information to proceed",
        ...toastErrorConfig,
      });
      return;
    }

    if (
      !localInputs.recipientContactEmail.includes("@") ||
      !localInputs.recipientContactEmail.includes(".")
    ) {
      toast.show({
        description: "Recipient email is invalid",
        ...toastErrorConfig,
      });
      return;
    }

    const isValidRecipientPhone =
      localInputs.recipientContactPhone &&
      localInputs.recipientContactPhone.replace(/\D/g, "").length === 11;

    if (!isValidRecipientPhone) {
      toast.show({
        description: "Please enter a valid 10-digit recipient's phone number",
        ...toastErrorConfig,
      });
      return;
    }

    // Dispatch localInputs to Redux
    Object.entries(localInputs).forEach(([key, value]) => {
      dispatch(setInputValue({ key, value }));
    });

    navigate(
      location.pathname.includes("request-edit")
        ? `/request-edit/4/${id}`
        : id
        ? `/edit/4/${id}`
        : "/create/4"
    );
  };

  const prevStep = () => {
    navigate(
      location.pathname.includes("request-edit")
        ? `/request-edit/2/${id}`
        : id
        ? `/edit/2/${id}`
        : "/create/2"
    );
  };

  const [wasDeliveryChecked, setWasDeliveryChecked] =
    useState(deliveryPINRequired);

  const handleCheckboxClick = (location) => {
    if (location === "delivery" && wasDeliveryChecked) {
      setModalLocation("delivery");
      setShownCancelPINModal(true);
    } else {
      // Directly toggle without modal
      if (location === "delivery") {
        dispatch(
          setInputValue({
            key: "deliveryPINRequired",
            value: !deliveryPINRequired,
          })
        );
        setWasDeliveryChecked(!deliveryPINRequired);
      }
    }
  };

  const CancelPINAlert = () => {
    return (
      <AlertDialog
        isOpen={shownCancelPINModal}
        onClose={handleCloseModal}
        useRNModal={true}
      >
        <AlertDialog.Content>
          <AlertDialog.CloseButton />
          <AlertDialog.Header>
            <Text>
              Cancel {modalLocation === "pickup" ? "pickup" : "delivery"} PIN
            </Text>
          </AlertDialog.Header>
          <AlertDialog.Body>
            <Text>
              By choosing to cancel the PIN code, you acknowledge and accept
              full responsibility for ensuring the
              {modalLocation === "pickup" ? " pickup" : " delivery"}?
            </Text>
          </AlertDialog.Body>
          <AlertDialog.Footer>
            <Button.Group space={2}>
              <Button
                style={styles.cancel}
                variant="cancel"
                onPress={handleCloseModal}
              >
                Go back
              </Button>
              <Button
                variant="fatal"
                style={styles.dcButton}
                onPress={handleCancelPIN}
              >
                Cancel PIN
              </Button>
            </Button.Group>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>
    );
  };

  const handleSelectPlace = (place) => {
    setLocalInputValue(place.placeName);
    setLocalInputs({
      deliveryLocation: place.address,
      recipientContactName: place.fullName,
      recipientContactPhone: place.phone,
      recipientContactEmail: place.email,
      checkboxRecipient: false,
    });
    setIsDropdownVisible(false);
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      if (localInputValue.trim()) {
        const filtered = places.filter(
          (place) =>
            place.placeName
              .toLowerCase()
              .includes(localInputValue.toLowerCase()) ||
            place.address.toLowerCase().includes(localInputValue.toLowerCase())
        );

        setFilteredPlaces(filtered);
      } else {
        setFilteredPlaces([]);
        setIsDropdownVisible(false);
      }
    }, 300); // Debounce for 300 milliseconds

    return () => {
      clearTimeout(handler);
    };
  }, [localInputValue, places]);

  return (
    <>
      <View style={styles.container}>
        <Text style={styles.h1}>
          {location.pathname.includes("request-edit")
            ? "Request edit"
            : id
            ? "Edit order"
            : "Create order"}
        </Text>
        <View style={styles.stepper}>
          <Stepper currentStep={3} />
        </View>
        <View>
          <Text
            style={{ fontWeight: "bold", fontSize: 18, marginVertical: 10 }}
          >
            Delivery information
          </Text>
          {places && places.length > 0 && (
            <>
              <FormControl.Label>
                Prefill with saved place (optional)
              </FormControl.Label>
              <Input
                style={{
                  backgroundColor: "#F5F5F5", // Light gray background
                }}
                placeholder="Type to find saved place..."
                value={localInputValue}
                onChangeText={(text) => {
                  setLocalInputValue(text);
                  if (text.length > 0) {
                    setIsDropdownVisible(true);
                  } else {
                    setIsDropdownVisible(false);
                  }
                }}
                onFocus={() => {
                  if (localInputValue.length > 0) {
                    setIsDropdownVisible(true);
                  }
                }}
                onBlur={() =>
                  setTimeout(() => {
                    if (localInputValue.length > 0) {
                      setIsDropdownVisible(false);
                    }
                  }, 100)
                } // Delays hiding dropdown to allow selection
              />
              {isDropdownVisible && (
                <View style={styles.dropdownContainer}>
                  {filteredPlaces.map((place) => (
                    <Pressable
                      key={place.id}
                      onPress={() => handleSelectPlace(place)}
                      style={styles.dropdownItem}
                    >
                      <Text>{place.placeName}</Text>
                      <Text>{place.address}</Text>
                    </Pressable>
                  ))}
                </View>
              )}
            </>
          )}
          <View style={styles.divider} />
          <FormControl.Label>Delivery Location</FormControl.Label>
          <View style={styles.dateButtonContainer}>
            <Geo />
            <Autocomplete
              style={{
                width: "100%",
                border: 0,
                paddingLeft: 14,
                outline: "none",
                marginBottom: 8,
              }}
              placeholder={localInputs.deliveryLocation || "Search"}
              onPlaceSelected={(details) => {
                if (details?.geometry?.location) {
                  const location = JSON.parse(
                    JSON.stringify(details?.geometry?.location)
                  );
                  location.title = "Delivery Location";
                  setDeliveryLocationCoordinates(location);
                  handleChange("deliveryLocation", details?.formatted_address);
                }
              }}
              options={{
                types: ["address"],
                componentRestrictions: { country: "us" },
              }}
            />
          </View>
          <View style={styles.row}>
            <Checkbox
              value="checkbox"
              isChecked={deliveryPINRequired}
              onChange={() => handleCheckboxClick("delivery")}
              accessibilityLabel="checkbox"
            />
            <Text style={styles.textContainer} fontSize="sm">
              PIN required at delivery
            </Text>
          </View>
          <Text style={{ fontWeight: "bold", fontSize: 18 }}>
            Recipient information
          </Text>
          <View style={styles.row}>
            <Center>
              <Checkbox
                value="checkbox"
                isChecked={localInputs.checkboxRecipient}
                // defaultIsChecked={localInputs.checkboxRecipient}
                onChange={(state) => {
                  handleChange("checkboxRecipient", state);
                  if (state) {
                    handleChange("recipientContactName", fullName);
                    handleChange("recipientContactEmail", email);
                    handleChange("recipientContactPhone", phone);
                  } else {
                    handleChange("recipientContactName", "");
                    handleChange("recipientContactEmail", "");
                    handleChange("recipientContactPhone", "");
                  }
                }}
                accessibilityLabel="checkbox"
              />
            </Center>

            <Text style={styles.textContainer} fontSize="sm">
              Will the account owner be the recipient?
            </Text>
          </View>
          <FormControl.Label>Recipient Name</FormControl.Label>
          <Input
            value={localInputs.recipientContactName}
            onChangeText={(value) => {
              handleChange("recipientContactName", value);
            }}
            type="text"
            isDisabled={localInputs.checkboxRecipient}
            InputLeftElement={
              <View style={styles.leftIcon}>
                <User />
              </View>
            }
            placeholder="Full Name"
          />
          <View style={styles.divider} />
          <FormControl.Label>Recipient Email</FormControl.Label>
          <Input
            value={localInputs.recipientContactEmail}
            onChangeText={(value) => {
              handleChange("recipientContactEmail", value);
            }}
            textContentType="emailAddress"
            keyboardType="email-address"
            autoCapitalize="none"
            type="email"
            isDisabled={localInputs.checkboxRecipient}
            autoCorrect={false}
            autoCompleteType="email"
            InputLeftElement={
              <View style={styles.leftIcon}>
                <Email />
              </View>
            }
            placeholder="Email Address"
          />
          <View style={styles.divider} />
          <FormControl.Label>Recipient Phone Number</FormControl.Label>
          <View
            style={[
              {
                flexDirection: "row",
                alignItems: "center",
                borderRadius: 12,
                borderColor: isRecipientPhoneFocused ? "#1DCE80" : "#D9D9D9",
                backgroundColor: isRecipientPhoneFocused ? "#E9F8EF" : "FAFAFA",
                borderWidth: 1,
                height: 46,
                width: "100%",
                paddingHorizontal: 12,
                outline: isRecipientPhoneFocused ? "1px solid #1DCE80" : "none",
                opacity: localInputs.checkboxRecipient ? 0.5 : 1,
              },
            ]}
          >
            <View style={{ marginRight: 10 }}>
              <Phone />
            </View>
            <Text>+1 </Text>
            <PhoneInput
              value={localInputs.recipientContactPhone}
              onChange={(value) => {
                handleChange("recipientContactPhone", value);
              }}
              type="phone"
              maxLength="14"
              placeholder="(222) 333-4455"
              defaultCountry="US"
              style={{
                paddingLeft: 0,
                outline: "none",
                flex: 1,
                borderWidth: 0,
                backgroundColor: isRecipientPhoneFocused ? "#E9F8EF" : "white",
              }}
              onFocus={() => setIsRecipientPhoneFocused(true)}
              onBlur={() => setIsRecipientPhoneFocused(false)}
              disabled={localInputs.checkboxRecipient}
            />
          </View>
          <FormControl.Label style={{ marginTop: 20 }}>
            Remember this info if you place orders frequently to deliver here
          </FormControl.Label>
          <View style={{ width: 127 }}>
            <Button
              isDisabled={
                !active ||
                isPlaceSaved ||
                !(deliveryLocation || localInputs.deliveryLocation) ||
                !localInputs.recipientContactName ||
                !localInputs.recipientContactEmail ||
                !localInputs.recipientContactPhone
              }
              onPress={handleOpenSavePlaceModal}
              variant="outline"
            >
              Save place
            </Button>
          </View>
          <View style={styles.emptySpace} />
          <BottomNavigator
            nextOnPress={nextStep}
            nextText={"Next"}
            prevOnPress={prevStep}
            prevText={"Previous"}
          />
        </View>
      </View>
      <CancelPINAlert />
      <AlertDialog
        isOpen={isModalVisible}
        onClose={handleCloseSavePlaceModal}
        useRNModal={true}
      >
        <AlertDialog.Content>
          <AlertDialog.CloseButton />
          <AlertDialog.Header>Name Your Place</AlertDialog.Header>
          <AlertDialog.Body>
            <Input
              placeholder="Enter a name for the place"
              value={placeName}
              onChangeText={handleChangePlaceName}
              autoFocus
            />
          </AlertDialog.Body>
          <AlertDialog.Footer>
            <View style={styles.buttonsRow}>
              <Button
                style={styles.cancelButton}
                variant="cancel"
                onPress={handleCloseSavePlaceModal}
              >
                Cancel
              </Button>
              <Button
                style={styles.confirmButton}
                onPress={handleConfirmSavePlaceModal}
              >
                Confirm
              </Button>
            </View>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>
    </>
  );
}
