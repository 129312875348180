import * as React from "react";
import {
  Button,
  FormControl,
  View,
  Text,
  Center,
  Checkbox,
  Input,
  useToast,
} from "native-base";
import styles from "./styles";
import { useEffect, useState } from "react";
import { Year } from "../../../assets/images/svg";
import { formatDate } from "../../../utils";
import { useDispatch, useSelector } from "react-redux";
import { setInputValue } from "../../../networking/orders/ordersSlice";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import BottomNavigator from "../../../components/BottomNavigator";
import {
  toastErrorConfig,
  toastWarningConfig,
} from "../../../config/constants";
import Stepper from "../../../components/stepper";

export default function NOFThirdStepScreen() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const toast = useToast();
  const { orders } = useSelector((state) => state.profile);
  const profileOrder = orders.find((order) => order.id === id) || null;
  const orderState = useSelector((state) => state.order);
  const flexiblePickupDate = orderState.flexiblePickupDate;
  const flexibleDeliveryDate = orderState.flexibleDeliveryDate;
  const pickUpDate1 = orderState.pickUpDate1;
  const deliveryDate1 = orderState.deliveryDate1;

  const nextStep = () => {
    if (validateDates()) {
      navigate(
        location.pathname.includes("request-edit")
          ? `/request-edit/preview/${id}`
          : id
          ? `/edit/preview/${id}`
          : "/create/preview"
      );
    }
  };

  const prevStep = () => {
    navigate(
      location.pathname.includes("request-edit")
        ? `/request-edit/3/${id}`
        : id
        ? `/edit/3/${id}`
        : "/create/3"
    );
  };

  const validateDates = () => {
    let valid = true;
    if (!pickUpDate1) {
      valid = false;
    }
    if (!deliveryDate1 || (pickUpDate1 && deliveryDate1 < pickUpDate1)) {
      valid = false;
    }
    if (!valid) {
      toast.show({
        description: "Please fill in the required dates.",
        ...toastErrorConfig,
      });
    }
    return valid;
  };

  const addDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result.toISOString().split("T")[0];
  };

  const getDateDifference = (date1, date2) => {
    const diffTime = new Date(date2) - new Date(date1);
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  };

  const handleChange = (key, value) => {
    dispatch(setInputValue({ key, value }));
  };

  return (
    <View style={styles.container}>
      <Text style={styles.h1}>
        {location.pathname.includes("request-edit")
          ? "Request edit"
          : id
          ? "Edit order"
          : "Create order"}
      </Text>
      <View style={styles.stepper}>
        <Stepper currentStep={4} />
      </View>
      <View>
        <View style={styles.divider} />
        <FormControl.Label>Pick Up Date</FormControl.Label>
        <View style={styles.row}>
          <View>
            <View
              style={[
                styles.inputBorder,
                profileOrder &&
                  profileOrder.status === "pickedUp" && {
                    backgroundColor: "#F0F0F0",
                  },
              ]}
            >
              <input
                style={{ borderWidth: 0, color: "#A3A3A3" }}
                onChange={(e) => {
                  const selectedDate = e.target.value;
                  const today = new Date().toISOString().split("T")[0];

                  if (selectedDate < today) {
                    toast.show({
                      description:
                        "Please select a valid date (today or later)",
                      ...toastErrorConfig,
                    });
                    return;
                  }
                  handleChange("pickUpDate1", selectedDate);
                  if (deliveryDate1) {
                    const diffDays = getDateDifference(
                      selectedDate,
                      deliveryDate1
                    );
                    if (diffDays > 12) {
                      handleChange("deliveryDate1", "");
                      toast.show({
                        description:
                          "Delivery date must be within 12 days after pickup",
                        ...toastWarningConfig,
                      });
                    } else if (diffDays < 2) {
                      handleChange("deliveryDate1", "");
                      toast.show({
                        description:
                          "Delivery must be at least 2 days after pickup",
                        ...toastWarningConfig,
                      });
                    }
                  }
                }}
                value={pickUpDate1 || ""}
                type="date"
                name="pickUpDate1"
                min={new Date().toISOString().split("T")[0]}
                disabled={profileOrder && profileOrder.status === "pickedUp"}
              />
            </View>
          </View>
          <View style={styles.checkbox}>
            <Checkbox
              isDisabled={profileOrder && profileOrder.status === "pickedUp"}
              value="checkbox"
              defaultIsChecked={flexiblePickupDate}
              onChange={(state) => handleChange("flexiblePickupDate", state)}
              accessibilityLabel="checkbox"
            />
            <Text style={styles.textContainer} fontSize="sm">
              Flexible Pickup Date
            </Text>
          </View>
        </View>

        <View style={styles.divider} />

        <FormControl.Label>Delivery Date</FormControl.Label>
        <View style={styles.row}>
          <View>
            <View style={styles.inputBorder}>
              <input
                style={{ borderWidth: 0, color: "#A3A3A3" }}
                onChange={(e) => {
                  const selectedDate = e.target.value;
                  const today = new Date().toISOString().split("T")[0];

                  if (pickUpDate1 && selectedDate < pickUpDate1) {
                    toast.show({
                      description:
                        "Delivery date must be after the pickup date",
                      ...toastErrorConfig,
                    });
                    return;
                  }
                  handleChange("deliveryDate1", selectedDate);
                  const diffDays = getDateDifference(pickUpDate1, selectedDate);
                  if (diffDays > 12) {
                    handleChange("pickUpDate1", "");
                    toast.show({
                      description:
                        "Pickup date must be within 12 days before delivery",
                      ...toastWarningConfig,
                    });
                  }
                }}
                value={deliveryDate1 || ""}
                type="date"
                name="deliveryDate1"
                min={
                  pickUpDate1
                    ? addDays(pickUpDate1, 2)
                    : new Date().toISOString().split("T")[0]
                }
              />
            </View>
          </View>
          <View style={styles.checkbox}>
            <Checkbox
              value="checkbox"
              defaultIsChecked={flexibleDeliveryDate}
              onChange={(state) => handleChange("flexibleDeliveryDate", state)}
              accessibilityLabel="checkbox"
            />
            <Text style={styles.textContainer} fontSize="sm">
              Flexible Delivery Date
            </Text>
          </View>
        </View>

        <View style={styles.emptySpace} />
      </View>

      <View style={styles.emptySpace} />
      <BottomNavigator
        nextOnPress={nextStep}
        nextText={"Next"}
        prevOnPress={prevStep}
        prevText={"Previous"}
      />
    </View>
  );
}
