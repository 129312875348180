import * as React from "react";
import {
  Button,
  Text,
  useToast,
  View,
  AlertDialog,
  Modal,
  Spinner,
  Divider,
  Box,
} from "native-base";
import styles from "./styles";
import { useEffect, useState } from "react";
import {
  CalendarGrey,
  GeoGreen,
  GreenEmail,
  GreenPhone,
  GreenUser,
  LocationGreen,
} from "../../../assets/images/svg";
import AddressGrey from "../../../assets/images/svg/address-grey";
import { useDispatch, useSelector } from "react-redux";
import {
  createOrder,
  editOrder,
  requestEditOrder,
} from "../../../networking/orders/ordersApi";
import { formatStringToDate } from "../../../utils";
import { GOOGLE_API_KEY } from "../../../config";
import { selectInputValues } from "../../../networking/orders/ordersSlice";
import BottomNavigator from "../../../components/BottomNavigator";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  APIProvider,
  Map,
  useMap,
  useMapsLibrary,
} from "@vis.gl/react-google-maps";
import {
  toastErrorConfig,
  toastSuccessConfig,
} from "../../../config/constants";
import { removeOrderById } from "../../../networking/profile/profileSlice";
import VehicleDetails from "../../../components/VehicleDetails";

export default function PreviewScreen() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const toast = useToast();
  const navigate = useNavigate();
  const [shownDisclaimerModal, setShownDisclaimerModal] = useState(false);
  const { active } = useSelector((state) => state.profile);
  const order = useSelector(selectInputValues);
  const position = { lat: 40.6973709, lng: -74.1444872 };
  const [isLoading, setIsLoading] = useState(false);

  const {
    pickUpLocation,
    deliveryLocation,
    pickUpDate1,
    deliveryDate1,
    senderContactName,
    senderContactPhone,
    senderContactEmail,
    recipientContactName,
    recipientContactPhone,
    recipientContactEmail,
    flexiblePickupDate,
    flexibleDeliveryDate,
    status,
    vehicles,
  } = order;

  const publish = () => {
    const { ...newOrder } = order;
    newOrder.pickUpDate2 = "1";
    newOrder.deliveryDate2 = "1";
    newOrder.statusToTMS = "sending";

    console.log("newOrder", newOrder);

    setIsLoading(true);
    dispatch(
      id ? editOrder({ id, data: newOrder }) : createOrder({ data: newOrder })
    )
      .unwrap()
      .then((response) => {
        // if (id) {
        //   dispatch(removeOrderById(id));
        // }
        setIsLoading(false);
        if (response.success === true) {
          toast.show({
            description: id
              ? "Order is updated and sent"
              : "Order is created and sent",
            ...toastSuccessConfig,
          });
        } else {
          toast.show({
            description: "Failed to send the order",
            ...toastErrorConfig,
          });
        }
        navigate("/orders");
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.data.statusCode === 400) {
          toast.show({
            description:
              err.data.errorResponse ||
              "An error occurred while publishing the order",
            ...toastErrorConfig,
          });
        } else {
          toast.show({
            description: "An error occurred while publishing the order",
            ...toastErrorConfig,
          });
        }
      });
  };

  const saveDraft = () => {
    const { ...draftOrder } = order;
    draftOrder.pickUpDate2 = "1";
    draftOrder.deliveryDate2 = "1";
    draftOrder.statusToTMS = "draft";

    console.log("draftOrder", draftOrder);

    dispatch(
      id
        ? editOrder({ id, data: draftOrder })
        : createOrder({ data: draftOrder })
    )
      .unwrap()
      .then(() => {
        // if (id) {
        //   return dispatch(removeOrderById(id));
        // }
      })
      .then(() => {
        toast.show({
          description: `Order ${id ? "is updated and " : ""}saved as draft`,
          ...toastSuccessConfig,
        });
        navigate("/orders");
      })
      .catch((err) => {
        toast.show({
          description: "Failed to update and save draft",
          ...toastErrorConfig,
        });
      });
  };

  const requestEdit = () => {
    const { ...orderWithEditRequest } = order;
    orderWithEditRequest.pickUpDate2 = "1";
    orderWithEditRequest.deliveryDate2 = "1";
    orderWithEditRequest.statusToTMS =
      status === "waitingPickUp"
        ? "waitingPickUpEditPending"
        : "pickedUpEditPending";

    setIsLoading(true);

    dispatch(requestEditOrder({ id, data: orderWithEditRequest }))
      .unwrap()
      .then((response) => {
        setIsLoading(false);
        toast.show({
          description: "Order edit request sent",
          ...toastSuccessConfig,
        });
        navigate("/orders");
      })
      .catch((err) => {
        setIsLoading(false);
        toast.show({
          description: "Failed to send order edit request",
          ...toastErrorConfig,
        });
        console.error("Error senidng order edit request:", err);
      });
  };

  const prevStep = () => {
    navigate(
      location.pathname.includes("request-edit")
        ? `/request-edit/4/${id}`
        : id
        ? `/edit/4/${id}`
        : "/create/4"
    );
    // navigate(id ? `/edit/4/${id}` : "/create/4");
  };

  const PublishOrderAlert = () => {
    const onClose = () => {
      setShownDisclaimerModal(false);
    };
    const onPublish = () => {
      setShownDisclaimerModal(false);
      publish();
    };

    return (
      <AlertDialog
        isOpen={shownDisclaimerModal}
        onClose={onClose}
        useRNModal={true}
      >
        <AlertDialog.Content>
          <AlertDialog.CloseButton />
          <AlertDialog.Header>Publish Order</AlertDialog.Header>
          <AlertDialog.Body>
            Attention! If there is no activity and confirmation of your order
            within 24 hours, your listing will be removed from the board.
          </AlertDialog.Body>
          <AlertDialog.Footer>
            <View style={styles.buttonsRow}>
              <Button style={styles.cancel} variant="cancel" onPress={onClose}>
                Cancel
              </Button>
              <Button style={styles.publishButton} onPress={onPublish}>
                Publish
              </Button>
            </View>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>
    );
  };

  const Directions = () => {
    const map = useMap();
    const routesLibrary = useMapsLibrary("routes");
    const [directionsService, setDirectionService] = useState();
    const [directionsRenderer, setDirectionsRenderer] = useState();
    const [routes, setRoutes] = useState([]);

    useEffect(() => {
      if (!routesLibrary || !map) return;
      setDirectionService(new routesLibrary.DirectionsService());
      setDirectionsRenderer(new routesLibrary.DirectionsRenderer({ map }));
    }, [routesLibrary, map, pickUpLocation, deliveryLocation]);

    useEffect(() => {
      if (!directionsService || !directionsRenderer) return;
      const dirSer = new routesLibrary.DirectionsService();
      const dirRenderer = new routesLibrary.DirectionsRenderer({ map });
      // dirService
      dirSer
        .route({
          origin: pickUpLocation,
          destination: deliveryLocation,
          travelMode: google.maps.TravelMode.DRIVING,
          provideRouteAlternatives: false,
        })
        .then((response) => {
          dirRenderer.setDirections(response);
          setRoutes(response.routes);
        });
    }, [directionsService, directionsRenderer]);
  };

  const MapD = () => {
    return (
      <APIProvider apiKey={GOOGLE_API_KEY}>
        <Map
          style={{ height: 400, marginTop: 10, width: "100%" }}
          defaultCenter={position}
          defaultZoom={10}
        >
          <Directions />
        </Map>
      </APIProvider>
    );
  };

  // useEffect(() => {
  //   Image.getSize(imageHost + mainImage, (width, height) => {
  //     setImageSize({
  //       width: Math.min(width, 600), // Max width of 800px
  //       height: Math.min(height, 600), // Max height of 800px
  //     });
  //   });
  // }, [mainImage]);

  useEffect(() => {
    if (!active) {
      navigate("/orders");
    }
  }, []);

  return (
    <>
      <View style={styles.container}>
        {vehicles.map((vehicle, index) => (
          <>
            <VehicleDetails vehicle={vehicle} index={index} />
          </>
        ))}

        <>
          <Box
            style={styles.locationPinWrapper}
            flexDirection={{ base: "column", sm: "row" }}
            justifyContent={{ base: "flex-start", sm: "space-between" }}
          >
            <View style={styles.locationDetailsWrapper}>
              <View
                style={{ flexDirection: "row", alignItems: "center", gap: 10 }}
              >
                <LocationGreen />
                <Text style={styles.locationText}>Pickup Location</Text>
              </View>
              <View style={styles.locationTextWrapper}>
                <View style={[styles.dateRow, { marginLeft: 2 }]}>
                  <AddressGrey />
                  <Text style={{ flex: 1, flexWrap: "wrap", marginLeft: 4 }}>
                    {pickUpLocation}
                  </Text>
                </View>

                <View style={styles.dateRow}>
                  <View style={styles.calendarIcon}>
                    <CalendarGrey />
                  </View>
                  <Text>
                    {formatStringToDate(pickUpDate1)}
                    {flexiblePickupDate ? " (Flexible)" : " (Exact date)"}
                  </Text>
                </View>
              </View>
            </View>
          </Box>
          <View my={2}>
            <Divider bg="gray.300" thickness="1" />
          </View>
          <Box
            style={styles.locationPinWrapper}
            flexDirection={{ base: "column", sm: "row" }}
            justifyContent={{ base: "flex-start", sm: "space-between" }}
          >
            <View style={styles.locationDetailsWrapper}>
              <View
                style={{ flexDirection: "row", alignItems: "center", gap: 10 }}
              >
                <GeoGreen />
                <Text style={styles.locationText}>Delivery Location</Text>
              </View>
              <View style={styles.locationTextWrapper}>
                <View style={[styles.dateRow, { marginLeft: 2 }]}>
                  <AddressGrey />
                  <Text style={{ flex: 1, flexWrap: "wrap", marginLeft: 4 }}>
                    {deliveryLocation}
                  </Text>
                </View>

                <View style={styles.dateRow}>
                  <View style={styles.calendarIcon}>
                    <CalendarGrey />
                  </View>
                  <Text>
                    {formatStringToDate(deliveryDate1)}
                    {flexibleDeliveryDate ? " (Flexible)" : " (Exact date)"}
                  </Text>
                </View>
              </View>
            </View>
          </Box>
        </>

        {/* <View style={styles.locationWrapper}>
          <LocationGreen />
          <View style={styles.locationTextWrapper}>
            <Text style={styles.locationText}>Pick Up Location</Text>
            <View style={styles.dateRow}>
              <View style={styles.addressIcon}>
                <AddressGrey />
              </View>
              <Text>{pickUpLocation}</Text>
            </View>
            <View style={styles.dateRow}>
              <View style={styles.calendarIcon}>
                <CalendarGrey />
              </View>
              <Text>{formatStringToDate(pickUpDate1)}</Text>
            </View>
          </View>
        </View>
        <View style={styles.locationWrapper}>
          <GeoGreen />
          <View style={styles.locationTextWrapper}>
            <Text style={styles.locationText}>Delivery Location</Text>
            <View style={styles.dateRow}>
              <View style={styles.addressIcon}>
                <AddressGrey />
              </View>
              <Text>{deliveryLocation}</Text>
            </View>
            <View style={styles.dateRow}>
              <View style={styles.calendarIcon}>
                <CalendarGrey />
              </View>
              <Text>{formatStringToDate(deliveryDate1)}</Text>
            </View>
          </View>
        </View> */}
        <MapD />
        <View style={{marginTop: 10}}>
          <Text style={styles.headerBlock}>Sender information</Text>
          <View style={styles.recipientRow}>
            <GreenUser />
            <Text style={styles.locationTextWrapper}>{senderContactName}</Text>
          </View>
          <View style={styles.recipientRow}>
            <GreenEmail />
            <Text style={styles.locationTextWrapper}>{senderContactEmail}</Text>
          </View>
          <View style={styles.recipientRow}>
            <GreenPhone />
            <Text style={styles.locationTextWrapper}>{senderContactPhone}</Text>
          </View>
          <View my={2}>
            <Divider bg="gray.300" thickness="1" />
          </View>
          <Text style={styles.headerBlock}>Recipient information</Text>
          <View style={styles.recipientRow}>
            <GreenUser />
            <Text style={styles.locationTextWrapper}>
              {recipientContactName}
            </Text>
          </View>
          <View style={styles.recipientRow}>
            <GreenEmail />
            <Text style={styles.locationTextWrapper}>
              {recipientContactEmail}
            </Text>
          </View>
          <View style={styles.recipientRow}>
            <GreenPhone />
            <Text style={styles.locationTextWrapper}>
              {recipientContactPhone}
            </Text>
          </View>
        </View>
        <PublishOrderAlert />
        <View style={styles.emptySpace} />
        <BottomNavigator
          nextOnPress={() => {
            location.pathname.includes("request-edit")
              ? requestEdit()
              : setShownDisclaimerModal(true);
          }}
          nextText={
            location.pathname.includes("request-edit")
              ? "Request edit"
              : "Publish"
          }
          prevOnPress={prevStep}
          prevText={"Previous"}
          showSaveButton={
            location.pathname.includes("request-edit") ? false : true
          }
          saveOnPress={saveDraft}
        />
      </View>
      <Modal
        isOpen={isLoading}
        onClose={() => {}}
        size="full"
        backdropOpacity={0.9}
        useRNModal={true}
      >
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner size="lg" color="#0000ff" />
          <Text style={{ color: "#ffffff", marginTop: 10, fontSize: 20 }}>
            Order is being sent...
          </Text>
        </View>
      </Modal>
    </>
  );
}
