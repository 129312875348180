const styles = {
  wrapper: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    minHeight: "80vh",
  },
  containerMobile: {
    width: 380,
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    backgroundColor: "#FAFAFA",
    borderRadius: 16,
    boxShadow: "0px 1px 2.22px rgba(0, 0, 0, 0.25)",
  },
  container: {
    alignItems: "center",
    justifyContent: "center",
    padding: 40,
    width: 415,
    backgroundColor: "#FAFAFA",
    borderRadius: 16,
    boxShadow: "0px 1px 2.22px rgba(0, 0, 0, 0.25)",
  },
  changePage: {
    paddingBottom: 30,
    marginTop: 10,
  },
  actionText: {
    fontWeight: "600",
    color: "#1DCE80",
  },
  leftIcon: {
    marginLeft: 12,
  },
  rightIcon: {
    marginRight: 12,
  },
  button: {
    marginTop: 14,
  },
  divider: {
    height: 16,
  },
  row: {
    flexDirection: "row",
  },
  textContainer: {
    flexShrink: 1,
    marginLeft: 8,
  },
};

export default styles;
